import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import iconLogo from "../../../assets/svgs/ditto-logo.svg";
import { notify } from "../../common/Notify";
import Loadershow from "../../common/Loadershow";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { loginUserAsync, selectIsLoading } from "../authSlice";
import { useForm } from "react-hook-form";
const ResetPassword = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams,] = useSearchParams( );

  const email = searchParams.get("email");
  const token = searchParams.get("token");
  console.log(email,token);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFormSubmit = (data) => {
    console.log(data);
    // dispatch(loginUserAsync());
  };

  const isLoading = useSelector(selectIsLoading);

  return (
    <>
      {isLoading && <Loadershow show={isLoading} />}
      <div className={`isolate  px-6 py-6 sm:py-6 lg:px-8 ${isLoading ? " opacity-25" : " opacity-100"}`}>
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mt-7 relative">
          <div className=" bg-white shadow-2xl border border-[#fce300] shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300] rounded-lg  flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8 sm:mx-auto sm:w-full sm:max-w-[36rem]">
            <div className="">
              <img
                className="mx-auto h-10 w-auto"
                src={iconLogo}
                alt="Ditto AirTable"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Create your password to login your account
              </h2>
            </div>

            <div className="mt-10 ">
              <form className="space-y-6" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Confirm Password
                    </label>

                  </div>
                  <div className="mt-2 relative">
                    <input
                      id="confirmPassword"
                      {...register("confirmPassword", { required: true })}
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                    <span
                      className=" absolute flex flex-row justify-center items-center top-2 right-5 "
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <EyeSlashIcon className="w-4 h-4" />
                      ) : (
                        <EyeIcon className="w-4 h-4" />
                      )}
                    </span>
                  </div>
                  {errors.confirmPassword && errors.confirmPassword?.type === "required" && (
                    <p role="alert" className="text-red-600">
                      <span className="text-red-600">- </span> Confirm Password is required
                    </p>
                  )}
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="repeat-password"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Repeat Confirm Password
                    </label>

                  </div>
                  <div className="mt-2 relative">
                    <input
                      id="repeat-password"
                      {...register("repeatPassword", { required: true })}
                      type={showPassword ? "text" : "password"}
                      autoComplete="repeat-password"
                      className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                    <span
                      className=" absolute flex flex-row justify-center items-center top-2 right-5 "
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <EyeSlashIcon className="w-4 h-4" />
                      ) : (
                        <EyeIcon className="w-4 h-4" />
                      )}
                    </span>
                  </div>
                  {errors.repeatPassword && errors.repeatPassword?.type === "required" && (
                    <p role="alert" className="text-red-600">
                      <span className="text-red-600">- </span> Repeat password is required
                    </p>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#fce300] px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:text-white shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default ResetPassword;

