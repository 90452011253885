import { BriefcaseIcon } from "@heroicons/react/24/outline";
const selectRoleOption = [
  {
    id: 0,
    name: "Choose Role",
    value: null,
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 1,
    name: "Admin",
    value: "admin",
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 2,
    name: "User",
    value: "user",
    icon: BriefcaseIcon,
    disable: true,
  },
];

const selectBrandOption = [
  {
    id: 0,
    name: "Choose Brand",
    value: null,
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 1,
    name: "Ditto",
    value: "ditto",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 2,
    name: "McCall's",
    value: "mccalls",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 3,
    name: "Liesl +Co.",
    value: "Liesl + Co.",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 4,
    name: "Kwik Sew",
    value: "kwik-sew",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 5,
    name: "Madalynne Intimates",
    value: "Madalynne Intimates",
    disable: false,
  },
  {
    id: 6,
    name: "Little Lizard King Patterns",
    value: "little-lizard-king-patterns",
    disable: false,
  },
  {
    id: 7,
    name: "Butterick",
    value: "Butterick",
    disable: false,
  },
  {
    id: 8,
    name: "Style Arc",
    value: "Style Arc",
    disable: false,
  },
  {
    id: 9,
    name: "Straight Stitch Society",
    value: "Straight Stitch Society",
    disable: false,
  }, 
  {
    id: 10,
    name: "Ditto Designer",
    value: "Ditto Designer",
    disable: false,
  },
];
const selectFabricOption = [
  {
    id: 0,
    name: "Choose Fabric",
    value: null,
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 1,
    name: "Knit",
    value: "knit",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 2,
    name: "Woven",
    value: "Woven",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 3,
    name: "Knit/Woven",
    value: "Knit Woven",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 4,
    name: "Other",
    value: "Other",
    icon: BriefcaseIcon,
    disable: false,
  },
];
const selectGenderOption = [
  {
    id: 0,
    name: "Choose Type",
    value: null,
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 1,
    name: "Women’s Patterns",
    value: "Women Patterns",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 2,
    name: "Men’s Patterns",
    value: "Men Patterns",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 3,
    name: "Kids Patterns ",
    value: "Kids Patterns ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 4,
    name: "Gender Neutral Patterns ",
    value: "Gender Neutral Patterns ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 6,
    name: "Boys ",
    value: "Boys ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 7,
    name: "Girls ",
    value: "Girls ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 8,
    name: "Gender Neutral Kids ",
    value: "Gender Neutral Kids ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 9,
    name: "Gender Neutral Adults ",
    value: "Gender Neutral Adults ",
    icon: BriefcaseIcon,
    disable: false,
  }
  
];
const selectProductTypeOption = [
  {
    id: 0,
    name: "Choose Type",
    value: null,
    icon: BriefcaseIcon,
    disable: true,
  },
  {
    id: 1,
    name: "Women’s Patterns",
    value: "Women Patterns",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 2,
    name: "Men’s Patterns",
    value: "Men Patterns",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 3,
    name: "Kids Patterns ",
    value: "Kids Patterns ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 4,
    name: "Gender Neutral Patterns ",
    value: "Gender Neutral Patterns ",
    icon: BriefcaseIcon,
    disable: false,
  },
  {
    id: 5,
    name: "Craft Patterns ",
    value: "Craft Patterns ",
    icon: BriefcaseIcon,
    disable: false,
  },
];
const HeadersMenu = [
  {
    id: 1,
    title: "About",
    href: "/app/about",
    active: true,
  },
  {
    id: 2,
    title: "My Patterns",
    href: "/app/show-records",
    active: false,
  },
  {
    id: 3,
    title: "Add Patterns",
    href: "/app/add-record",
    active: false,
  },
  {
    id: 4,
    title: "Support",
    href: "/app/contact-support",
    active: false,
  },
];

const AuthHeader = [
  {
    id: 1,
    title: "Login",
    href: "/app/login",
    active: false,
  },
];
const patterns = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
);

const selectPrintableOptions = [
  {
    name: "Choose Printable",
    value: null,
    disable: true,
  },
  { name: "Yes", value: "Yes", disable: false },
  {
    name: "No",
    value: "No",
    disable: false,
  },
];
export {
  selectBrandOption,
  HeadersMenu,
  AuthHeader,
  patterns,
  selectRoleOption,
  selectFabricOption,
  selectProductTypeOption,
  selectPrintableOptions,
  selectGenderOption,
};
