import "./App.css";
import { RouterProvider } from "react-router-dom";
import { routers } from "./Routes/routes";
import Loadershow from "./features/common/Loadershow";
function App() {
  return (
    <div className="h-full relative">
      <RouterProvider
        router={routers}
        future={{ v7_startTransition: true }}
        fallbackElement={<Loadershow show={true} />}
      />
    </div>
  );
}

export default App;
