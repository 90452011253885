import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUser,
  loginUser,
  forgotPassword,
  resetpassword,
  getAllUserList,
  verifyOtp,
  sendOtp,
  updateUser,
  checkUser,
} from "./authApi";
import { notify } from "../common/Notify";
import { globalState } from "../app/globalState";
export const loginUserAsync = createAsyncThunk(
  "auth/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await loginUser(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const updateUserAsync = createAsyncThunk(
  "auth/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUser(data);
      const { clearErrors, reset, handleEditTab } = data;
      reset();
      clearErrors();
      handleEditTab();
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "auth/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createUser(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const forgotPasswordAsync = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await forgotPassword(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const getAllUserListChunkAsync = createAsyncThunk(
  "auth/getAllUserListChunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllUserList(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const sendOtpAsync = createAsyncThunk(
  "auth/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await sendOtp(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const verifyOtpAsync = createAsyncThunk(
  "auth/verifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifyOtp(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const resetPasswordAsync = createAsyncThunk(
  "auth/resetpassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await resetpassword(data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const signOutUserAsync = createAsyncThunk(
  "auth/signOut",
  async (data, { rejectWithValue }) => {
    try {
      return true;
    } catch (error) {
      return true;
    }
  }
);
export const checkAuthUserAsync = createAsyncThunk(
  "auth/checkAuthUser",
  async (data, { rejectWithValue }) => {
    try {
      const checkUserDetails =
        globalState.getLocalStorage("user") &&
        globalState.getLocalStorage("user_authenticated");
      const checkUserStorageStr = globalState.getLocalStorage("user");
      if (!checkUserDetails || !checkUserStorageStr)
        return rejectWithValue(false);
      let checkUserStorage = JSON.parse(checkUserStorageStr);
      let payLoadData = {
        ...data,
        data: { ...checkUserStorage },
        url: "auth/checkUser",
      };
      // let response = await checkUser(payLoadData);
      // return response.data;
      return { data: JSON.parse(globalState.getLocalStorage("user")) };
    } catch (error) {
      return false;
    }
  }
);
export const resetNewUserAsync = createAsyncThunk(
  "auth/resetNewUser",
  async (data, { rejectWithValue }) => {
    try {
      // const checkUser =globalState.getLocalStorage("user") && globalState.getLocalStorage("user_authenticated");
      // if(!checkUser)
      //               return rejectWithValue(false);
      // else
      //               return false;
      return true;
    } catch (error) {
      return true;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: 0,
    user: {},
    isFetchedData: false,
    isLoading: false,
    error: null,
    isLoginUser: false,
    isNewUserCreate: false,
    newCreateUser: {},
    getAllUserList: [],
    getAllUserCount: 0,
    isRedirected: false,
    isLoadingGetAllUser: false,
    isErrorFetchingUserList: false,
    errorGetUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.isNewUserCreate = true;
        // console.log(action);
        state.newCreateUser = action.payload.user;
        // state.user = action.payload.user;
        // globalState.setLocalStorage("user",JSON.stringify(action.payload.user));
        // globalState.setLocalStorage("user_authenticated", true);
        // state.error=null;
        state.isLoading = false;
        // state.isLoginUser=true;
      })
      .addCase(resetNewUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(resetNewUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.isNewUserCreate = false;
        state.newCreateUser = {};
        state.isLoading = false;
        state.isRedirected = false;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isNewUserCreate = false;
        // console.log(action);
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
        state.newCreateUser = {};
        state.isLoading = false;
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isLoginUser = false;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.user;
        globalState.setLocalStorage(
          "user",
          JSON.stringify(action.payload.user)
        );
        globalState.setLocalStorage("user_authenticated", true);
        state.error = null;
        state.isLoading = false;
        state.isLoginUser = true;
        state.isRedirected = false;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isLoading = false;
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
        state.isLoginUser = false;
      })
      .addCase(sendOtpAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(sendOtpAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        notify.info(action.payload?.message ?? "OTP sent successfully");
        state.isLoading = false;
        state.isRedirected = false;
      })
      .addCase(sendOtpAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isLoading = false;
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
      })
      .addCase(verifyOtpAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(verifyOtpAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.isLoading = false;
        state.isRedirected = false;
      })
      .addCase(verifyOtpAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isLoading = false;
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        if (action.payload?.data && action.payload.data !== undefined) {
          state.user = action.payload?.data;
          let allUserList =state.getAllUserList;
          let index =allUserList.findIndex(user=>user.id=== action.payload.data.id);
          allUserList[index]=action.payload?.data;
          state.getAllUserList=allUserList;
          globalState.setLocalStorage(
            "user",
            JSON.stringify(action.payload.data)
          );
        }
        state.isLoading = false;
        state.isRedirected = false;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isLoading = false;
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
      })
      .addCase(getAllUserListChunkAsync.pending, (state) => {
        state.status = "loading";
        state.isLoadingGetAllUser = true;
      })
      .addCase(getAllUserListChunkAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.getAllUserList = action.payload.data;
        state.getAllUserCount = action.payload.totalCountData;
        state.errorGetUser = null;
        state.isLoadingGetAllUser = false;
      })
      .addCase(getAllUserListChunkAsync.rejected, (state, action) => {
        state.status = "idle";
        state.errorGetUser = action.payload.message;
        notify.error(action.payload.message);
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
        state.isLoadingGetAllUser = false;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isLoginUser = false;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action);
        state.error = null;
        state.isLoading = false;
        state.isLoginUser = true;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
        state.isLoading = false;
        state.isLoginUser = false;
      })
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isLoginUser = false;
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.isLoading = false;
        state.isLoginUser = true;
      })
      .addCase(forgotPasswordAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
        state.isLoading = false;
        state.isLoginUser = false;
      })
      .addCase(signOutUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isLoginUser = false;
      })
      .addCase(signOutUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.user = {};
        globalState.removeLocalStorage("user");
        globalState.removeLocalStorage("user_authenticated");
        state.isRedirected = false;
        state.isLoading = false;
        state.isLoginUser = false;
        state.isNewUserCreate = false;
        state.newCreateUser = {};
      })
      .addCase(signOutUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.user = {};
        globalState.removeLocalStorage("user");
        globalState.removeLocalStorage("user_authenticated");
        state.isLoading = false;
        state.isLoginUser = false;
        state.isNewUserCreate = false;
        state.newCreateUser = {};
        state.isRedirected = false;
      })
      .addCase(checkAuthUserAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(checkAuthUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        // let user = action.payload?.user || {};
        // if (user && Object.keys(user).length) {
        //   globalState.removeLocalStorage("user");
        //   globalState.removeLocalStorage("user_authenticated");
        //   globalState.setLocalStorage(
        //     "user",
        //     JSON.stringify(action.payload.user)
        //   );
        //   globalState.setLocalStorage("user_authenticated", true);
        // }
        // state.user = !user ? {} : user;
        if (action.payload?.data) {
          state.user = action.payload.data;
        }
        state.isLoading = false;
        state.isLoginUser = true;
      })
      .addCase(checkAuthUserAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.user = {};
        globalState.removeLocalStorage("user");
        globalState.removeLocalStorage("user_authenticated");
        state.isLoading = false;
        state.isLoginUser = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const selectUserInfo = (state) => state.auth.user;
export const selectIsFetchedData = (state) => state.auth.isFetchedData;
export const selectUserError = (state) => state.auth.error;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsLoginUser = (state) => state.auth.isLoginUser;
export const selectIsNewUserCreate = (state) => state.auth.isNewUserCreate;
export const selectNewCreateUser = (state) => state.auth.newCreateUser;
export const selectGetAllUserList = (state) => state.auth.getAllUserList;
export const selectGetAllUserListCount = (state) => state.auth.getAllUserCount;
export const selectIsRedirected = (state) => state.auth.isRedirected;
export const selectGetAllUserError = (state) => state.auth.errorGetUser;
export const selectIsLoadingGEtAllUser = (state) =>
  state.auth.isLoadingGetAllUser;
export default authSlice.reducer;
