import React from "react";
import ForgotPassword from "../../auth/components/ForgotPassword";
const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPassword />
    </>
  );
};

export default ForgotPasswordPage;
