import React from "react";
import Footer from "../../common/Footer";
import DashboardIMg from "../../../assets/img/Ditto_event_00033 copy.jpeg";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import Wave from "react-wavify";
const Dashboard = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden  px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={
              {
                // clipPath:
                //   "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }
            }
          />
          <svg
            className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
            aria-hidden="true">
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse">
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        {/* <div>
    <Wave fill='#fce300'
        paused={false}
        style={{ display: 'flex' }}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3
        }}
  />
    </div> */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-[85rem] lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-black">
                  Let’s Ditto, Together{" "}
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl border-b-2 border-dotted border-[#fce300]">
                  View your patterns like never before.
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  We are transforming the world’s oldest sewing problem into a
                  journey of creative simplicity.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden ">
            <img
              className=" w-[48rem] h-[40rem] max-w-none rounded-xl bg-gray-900 ring-1 ring-gray-400/10 sm:w-[57rem] shadow-2xl"
              src={DashboardIMg}
              alt="ditto-banner"
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-[85rem] lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <p>
                  Joining Ditto provides a time-saving, eco-friendly option for
                  your customers in just a few clicks. Your patterns will
                  project exactly to scale and maintain your fit standards.
                  Review the steps below to get started.
                </p>
                <ul  className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <CloudArrowUpIcon
                      className=" animate-bounce mt-1 h-5 w-5 flex-none text-[#fce300]"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Select
                      </strong>{" "}
                      Add Patterns at the top of this page.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LockClosedIcon
                      className="animate-bounce mt-1 h-5 w-5 flex-none text-[#fce300]"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Fill out the fields
                      </strong>{" "}
                      for each pattern and add the sizes.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ServerIcon
                      className=" animate-bounce mt-1 h-5 w-5 flex-none text-[#fce300]"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Upload
                      </strong>{" "}
                      the product to our test environment. Check the accuracy of
                      all fields and photos as they will appear to customers.
                      When finished, confirm that the product is complete and
                      ready to post.
                    </span>
                  </li>
                </ul>
                <p className="mt-8">
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien
                  duis odio id et. Id blandit molestie auctor fermentum
                  dignissim. Lacus diam tincidunt ac cursus in vel. Mauris
                  varius vulputate et ultrices hac adipiscing egestas. Iaculis
                  convallis ac tempor et ut. Ac lorem vel integer orci.
                </p>
                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                  Question ?
                </h2>
                <p className="mt-6">
                  Contact our team via the Support tab at the top of the page,
                  or call 1-888-844-5582. We are available weekdays from 9:00
                  AM-5:00 PM EST.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
