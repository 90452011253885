import { configureStore, createReducer } from "@reduxjs/toolkit";
import addDataRecordReducer from "../components/Datatable/addDataSlice/addDataSlice";
import getDataRecordReducer from "../components/Datatable/getDataRecord/getDataRecordSlice";
import authReducer from "../auth/authSlice";
import contactReducer from "../components/Pages/contactFeature/ContactSlice";
export default configureStore({
  reducer: {
    addDataRecord: addDataRecordReducer,
    getDataRecord: getDataRecordReducer,
    auth: authReducer,
    contact: contactReducer,
  },
});
