// const url='https://localhost:5000/api/';
import {config} from "../common/Constraint";
const url=`/api/`;
// const url=`/api/`;

export const requestApi ={
              get:async (baseUrl)=>{
                            try{
                                          var response =await fetch(url+baseUrl);
                                          // if (!response.ok) {
                                          //               const message = `An error has occured: ${response.status}`;
                                          //               return await response.json()
                                          // }
                                          return await response.json();
                            }
                            catch(e){
                                          return e;
                            }
                            
              },
              post:async (baseUrl,data)=>{
                            try{
                            var response =await fetch(url+baseUrl,prepareHeaders('POST',data));
                            // if (!response.ok) {
                            //               const message = `An error has occured: ${response.status}`;
                            //               throw new Error(response);
                            // }
                            return await response.json();
              }
              catch(e){
                            return e;
              }
              },
              postFile:async (baseUrl,data)=>{
                            try{
                            var response =await fetch(url+baseUrl,prepareHeadersForFiles('POST',data));
                            // if (!response.ok) {
                            //               const message = `An error has occured: ${response.status}`;
                            //               throw new Error(response);
                            // }
                            return await response.json();
              }
              catch(e){
                            return e;
              }
              },
              put:async (baseUrl,data)=>{
                            var response =await fetch(url+baseUrl,prepareHeaders('PUT',data));
                            // if (!response.ok) {
                            //               const message = `An error has occured: ${response.status}`;
                            //               throw new Error(response);
                            // }
                            return await response.json();
              },
              delete:async (baseUrl,data)=>{
                            var response =await fetch(url+baseUrl,prepareHeaders('DELETE',data));
                            // if (!response.ok) {
                            //               const message = `An error has occured: ${response.status}`;
                            //               throw new Error(response);
                            // }
                            return await response.json();
              }
}

const prepareHeaders=(method,data)=>{
              return {
                            method:method,
                            body:JSON.stringify(data),
                            headers: {
                                          Accept: 'application/json',
                                          'Content-Type': 'application/json',
                            }
              }
}
const prepareHeadersForFiles=(method,data)=>{
              return {
                            method:method,
                            body:data,
              }
}