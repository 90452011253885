import React from 'react'
import Signup from '../../auth/components/Signup';
import Footer from '../../common/Footer';
const SignupPage = () => {
              return (
              <><Signup />
              <Footer /></>
              )
}

export default SignupPage;