import React from "react";
import Loader from "react-js-loader";
import { CirclesWithBar } from 'react-loader-spinner'
const Loadershow = ({ show }) => {
  return (
    <>
      {show && (
        <div
          className="flex items-center min-h-screen justify-center w-full z-[1000] opacity-100 top-0 bottom-0 left-0 fixed"
          style={{
            background: "rgba(0,0,0,.1)",
          }}>
          {/* <Loader
            type="box-rectangular"
            bgColor={"#fce300"}
            color={"#fce300"}
            size={100}
          /> */}
          <CirclesWithBar
                height="100"
                width="100"
                color="#fce300"
                outerCircleColor="#444444"
                innerCircleColor="#444444"
                barColor="#fce300"
                ariaLabel="circles-with-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
        </div>
      )}
    </>
  );
};

export default Loadershow;
