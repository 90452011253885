import React from 'react'
import { Link } from 'react-router-dom';
const Errorpages = () => {
  return (
              <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-32 lg:px-8">
              <div className="text-center">
                <p className="text-base font-semibold text-[#fce300]">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                    to="/app/dashboard"
                    className="rounded-md bg-[#fce300] px-3.5 py-2.5 text-sm font-semibold text-gray-800 shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]"
                  >
                    Go back home
                  </Link>
                  <Link to="/app/contact-support" className="text-sm font-semibold text-gray-900">
                    Contact Support <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </div>
            </main>
  )
}

export default Errorpages;