import React from "react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { DocumentPlusIcon, DocumentCheckIcon } from "@heroicons/react/24/solid";
import {
  selectBrandOption,
  selectFabricOption,
  selectProductTypeOption,
  selectPrintableOptions,
  selectGenderOption,
} from "../../../app/Constaint";
const CommonUI = () => {
  return <div>CommonUI</div>;
};

export default CommonUI;

export const addPatternsField = [
  {
    id: 1,
    name: "SFCC_Pattern_ID",
    key: "SFCC_Pattern_ID",
    fieldType: "text",
    required: true,
    cls: "sm:col-span-2 sm:col-start-1",
    errorMessage: "",
    labelHTML: "Pattern ID",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 2,
    name: "Pattern_Name",
    key: "Pattern_Name",
    fieldType: "text",
    required: true,
    cls: "sm:col-span-2",
    errorMessage: "",
    labelHTML: "Pattern Name",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 3,
    name: "Brand",
    key: "Brand",
    fieldType: "text",
    required: true,
    cls: "sm:col-span-2",
    errorMessage: "",
    labelHTML: "Brand",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectBrandOption,
    disable: false,
  },
  {
    id: 4,
    name: "Technical_Details",
    key: "Technical_Details",
    fieldType: "text",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Details",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 5,
    name: "Pattern_Description",
    key: "Pattern_Description",
    fieldType: "text",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Description",
    isTextField: true,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 14,
    name: "Fabric_Recommendation",
    key: "Fabric_Recommendation",
    fieldType: "text",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Fabric Recommendation",
    isTextField: true,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 6,
    name: "Fabric_type",
    key: "Fabric_type",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Fabric Type",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectFabricOption,
    disable: false,
  },
  {
    id: 7,
    name: "Notion",
    key: "Notion",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Notion",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 8,
    name: "Type",
    key: "Type",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Pattern Type",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectProductTypeOption,
    disable: false,
  },
  {
    id: 9,
    name: "Is_Printable",
    key: "Is_Printable",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Printable",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectPrintableOptions,
  },
  {
    id: 10,
    name: "Gender",
    key: "Gender",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Gender",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectGenderOption,
  },
  {
    id: 11,
    name: "Price",
    key: "Price",
    fieldType: "number",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Price",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 12,
    name: "Suitable_For",
    key: "Suitable_For",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Suitable For",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
  {
    id: 13,
    name: "is_Subscribable",
    key: "is_Subscribable",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Subscribable",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: true,
    selectedOption: selectPrintableOptions,
  },
  {
    id: 15,
    name: "Pattern_Fit",
    key: "Pattern_Fit",
    fieldType: "text",
    required: false,
    cls: "sm:col-span-3",
    errorMessage: "",
    labelHTML: "Pattern Fit",
    isTextField: false,
    isUpload: false,
    isPDF: false,
    isSelectField: false,
  },
];
export const addPatternsFieldPDF = [
  {
    id: 13,
    name: "Instruction_PDF",
    key: "Instruction_PDF",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Sewing Instructions PDF",
    isTextField: false,
    isUpload: true,
    isPDF: true,
    isSelectField: false,
    isMultipleFiles: false,
  },
  {
    id: 15,
    name: "Yardage_PDF",
    key: "Yardage_PDF",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Yardage PDF",
    isTextField: false,
    isUpload: true,
    isPDF: true,
    isSelectField: false,
    isMultipleFiles: false,
  },
];
export const addPatternsFieldImage = [
  {
    id: 14,
    name: "Yardage_Image",
    key: "Yardage_Image",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Yardage Image",
    isTextField: false,
    isUpload: true,
    isPDF: false,
    isSelectField: false,
    isMultipleFiles: false,
  },

  {
    id: 16,
    name: "Sketch_Image",
    key: "Sketch_Image",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Sketch Image",
    isTextField: false,
    isUpload: true,
    isPDF: false,
    isSelectField: false,
    isMultipleFiles: false,
  },
  {
    id: 17,
    name: "Size_Chart",
    key: "Size_Chart",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Size Chart",
    isTextField: false,
    isUpload: true,
    isPDF: false,
    isSelectField: false,
    isMultipleFiles: false,
  },
  {
    id: 18,
    name: "Main_Hero_Image",
    key: "Main_Hero_Image",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Main Hero Image",
    isTextField: false,
    isUpload: true,
    isPDF: false,
    isSelectField: false,
    isMultipleFiles: false,
  },
  {
    id: 19,
    name: "Hero_Images",
    key: "Hero_Images",
    fieldType: "file",
    required: false,
    cls: "col-span-full",
    errorMessage: "",
    labelHTML: "Hero Image URLs",
    isTextField: false,
    isUpload: true,
    isPDF: false,
    isSelectField: false,
    isMultipleFiles: true,
  },
];

export const InputField = ({
  cls,
  name,
  errorMessage,
  labelHTML,
  required,
  isTextField,
  fieldType,
  watch,
  register,
  errors,
}) => (
  <>
    <div className={cls}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900">
        {labelHTML} {required && <span className="text-red-600">*</span>}
      </label>
      <div className="mt-2">
        {isTextField ? (
          <textarea
            id={name}
            rows="3"
            {...register(name)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"></textarea>
        ) : (
          <input
            type={fieldType}
            {...register(name, { required: required })}
            id={name}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
          />
        )}
      </div>
      {isTextField && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          Optional Romance Copy: what season or occasion? What might you pair
          with it?
        </p>
      )}
      {required && errors[name]?.type === "required" && (
        <p role="alert" className="text-red-600">
          <span className="text-red-600">- </span> {labelHTML} is required
        </p>
      )}
    </div>
  </>
);

export const UploadImageUI = ({
  cls,
  name,
  errorMessage,
  labelHTML,
  required,
  fieldType,
  isMultipleFiles,
  watch,
  register,
  errors,
}) => (
  <>
    <div className={cls} key={name}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900">
        {labelHTML}
        {required && <span className="text-red-600">*</span>}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-[#fce300] px-6 py-10">
        <div className="text-center flex flex-col justify-center items-center">
          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor={name}
              className="relative cursor-pointer rounded-md bg-white font-semibold text-[#fce300] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#fce300] focus-within:ring-offset-2 hover:text-[#fce300]">
              {!watch(name) || watch(name).length === 0 ? (
                <>
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-[#fce300]"
                    aria-hidden="true"
                  />
                  <p className="text-xs leading-5 text-gray-600">UPLOAD</p>
                </>
              ) : (
                <>
                  <strong className="text-gray-900">
                    {!watch(name)?.length ? null : (isMultipleFiles ? `${watch(name)?.length} files are selected`: watch(name)[0]?.name)}{" "}
                  </strong>{" "}
                  <DocumentCheckIcon
                    className="mx-auto h-12 w-12 text-[#fce300]"
                    aria-hidden="true"
                  />
                </>
              )}
              <span>PNG, JPG, JPEG up to 3MB</span>
              <input
                id={name}
                {...register(name, { required: required })}
                type={fieldType}
                className="sr-only"
                accept=".jpg, .jpeg, .png"
                multiple={isMultipleFiles}
              />
            </label>
          </div>
        </div>
      </div>
      <p className="mt-3 text-sm leading-6 text-gray-600">{isMultipleFiles? "Multiple images" :"One image maximum"}</p>
      {required && errors[name]?.type === "required" && (
        <p role="alert" className="text-red-600">
          <span className="text-red-600">- </span>
          {labelHTML} is required
        </p>
      )}
    </div>
  </>
);
export const UploadPdfUI = ({
  cls,
  name,
  errorMessage,
  labelHTML,
  required,
  fieldType,
  isMultipleFiles,
  watch,
  register,
  errors,
}) => (
  <>
    <div className={cls} key={name}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900">
        {labelHTML} {required && <span className="text-red-600">*</span>}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <input
          id={name}
          {...register(name, { required: required })}
          type={fieldType}
          className="sr-only"
          accept="application/pdf"
          multiple={isMultipleFiles}
        />
        <div className="text-center justify-center items-center flex-col flex gap-3">
          {/* {console.log(watch(name))} */}
          {!watch(name) || watch(name).length === 0 ? (
            <>
              <DocumentPlusIcon
                className="mx-auto h-12 w-12 text-[#fce300]"
                aria-hidden="true"
              />
            </>
          ) : (
            <>
              <strong className="text-gray-900">
              {!watch(name)?.length ? null : (isMultipleFiles ? `${watch(name)?.length} files are selected`: watch(name)[0]?.name)}
              </strong>{" "}
              <DocumentCheckIcon
                className="mx-auto h-12 w-12 text-[#fce300]"
                aria-hidden="true"
              />
            </>
          )}

          <div className="mt-4 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor={name}
              className="relative cursor-pointer rounded-md bg-white font-semibold text-gray-800 focus-within:outline-none focus-within:ring-1 focus-within:ring-[#fce300] focus-within:ring-offset-2 hover:text-[#fce300]">
              <span>UPLOAD</span>
            </label>
            {/* <p className="pl-1">or drag and drop</p> */}
          </div>
          <p className=" text-sm leading-5 text-[#fce300]">
            PDF file up to <span className="text-[#fce300]">5 MB</span>
          </p>
        </div>
      </div>
      {required && errors[name]?.type === "required" && (
        <p role="alert" className="text-red-600">
          <span className="text-red-600">- </span>
          {labelHTML} is required
        </p>
      )}
    </div>
  </>
);
const Select = React.forwardRef(
  (
    {
      onChange,
      name,
      id,
      options,
      disable,
      selectedBrand,
      isRequired,
      register,
    },
    ref
  ) => {
    let filterBrand = null;

    if (selectedBrand) {
      filterBrand = options.filter((item) => {
        return item.value === selectedBrand;
      });
    } else {
      filterBrand = options[1];
    }
    // console.log(filterBrand)
    return (
      <>
        <select
          id={id}
          {...register(name, { required: isRequired })}
          disabled={disable}
          className=" appearance-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:max-w-full sm:text-sm sm:leading-6">
          {options.map((item, index) => (
            <option
              value={item.value}
              key={index}
              disabled={item.disable}
              className="hover:bg-[#fce300] text-gray-800 focus:bg-[#fce300]">
              {item.name}
            </option>
          ))}
        </select>
      </>
    );
  }
);
export const SelectField = ({
  cls,
  name,
  errorMessage,
  labelHTML,
  required,
  disable,
  selectedOption,
  watch,
  register,
  errors,
}) => (
  <>
    <div className={cls} key={name}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900">
        {labelHTML} {required && <span className="text-red-600">*</span>}
      </label>
      <div className="mt-2">
        <Select
          id={name}
          {...register(name, { required: required })}
          selectedBrand={selectedOption[0]}
          options={selectedOption}
          disable={disable}
          register={register}
        />
      </div>
      {required && errors[name]?.type === "required" && (
        <p role="alert" className="text-red-600">
          <span className="text-red-600">- </span> {labelHTML} is required
        </p>
      )}
    </div>
  </>
);
