import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Popover,
  Transition,
  Menu,
} from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate, useLocation } from "react-router-dom";
import iconLogo from "../../assets/svgs/ditto-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoginUser,
  signOutUserAsync,
  selectUserInfo,
  checkAuthUserAsync,
} from "../auth/authSlice";
import { HeadersMenu, AuthHeader } from "../app/Constaint";
import { globalState } from "../app/globalState";
import { selectIsRedirect } from "../components/Datatable/getDataRecord/getDataRecordSlice";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Headers = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isLoginUser = useSelector(selectIsLoginUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch;
  const user = useSelector(selectUserInfo);
  const isRedirect = useSelector(selectIsRedirect);
  const handleUserLogOut = () => {
    // dispatch(signOutUserAsync());
    navigate("/app/signout", { replace: true });
  };

  return (
    <>
      <header className="bg-white sticky top-0 z-50">
        <nav
          className="mx-auto flex max-w-[85rem] items-center justify-between p-6 lg:px-8 border-b border-gray-200"
          aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/app/dashboard" className="-m-1.5 p-1.5">
              <span className="sr-only">Ditto</span>
              <img
                className=" animate-bounce-slow h-8 w-auto"
                src={iconLogo}
                alt="Ditto Logo"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-[#fce300]"
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            {HeadersMenu.map((item, index) => (
              <Link
                to={item.href}
                className={`w-[120px] text-sm font-semibold leading-6 ${
                  location.pathname === item.href ||
                  location.pathname.includes(item.href)
                    ? "hover:text-[#fce300]  text-gray-900 hover:outline-1  bg-[#fce300] hover:outline hover:outline-[#fce300] rounded-full hover:bg-transparent"
                    : " border-transparent text-gray-900 hover:text-[#fce300]"
                } `}
                key={index}>
                <p className="m-1 text-center">{item.title}</p>
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {!isLoginUser && Object.keys(user).length === 0 ? (
              <div className="flex flex-row justify-center items-center gap-4 ">
                {AuthHeader.map((headerItem, index) => (
                  <Link
                    to={headerItem.href}
                    key={index}
                    className={`text-sm font-semibold leading-6  ${
                      headerItem.href === location.pathname
                        ? "text-[#fce300] hover:text-gray-900 "
                        : "text-gray-900} hover:text-[#fce300]"
                    } `}>
                    {headerItem.title}
                  </Link>
                ))}
              </div>
            ) : (
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="relative flex rounded-full p-2 text-bold bg-[#fce300] text-sm hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-[#fce300] hover:bg-transparent hover:text-[#fce300]">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <h3 className=" font-bold">{user && user.fullName}</h3>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {user.role === "admin" && (
                      <>
                        <Menu.Item>
                          {({ active }) => (
                            <Link to={"/app/signup"}>
                              <button
                                onClick={(e) => handleUserLogOut()}
                                className={classNames(
                                  active ? "" : "",
                                  "w-full flex justify-center items-center px-4 py-2 text-sm bg-white text-black hover:bg-[#fce300] "
                                )}>
                                Create Account
                              </button>
                            </Link>
                          )}
                        </Menu.Item>
                      </>
                    )}
                    <Menu.Item>
                      {({ active }) => (
                        <Link to={"/app/my-account"}>
                          <button
                            onClick={(e) => handleUserLogOut()}
                            className={classNames(
                              active ? "" : "",
                              "w-full flex justify-center items-center px-4 py-2 text-sm bg-white text-black hover:bg-[#fce300] "
                            )}>
                            My Account
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={(e) => handleUserLogOut()}
                          className={classNames(
                            active ? "" : "",
                            " w-full flex justify-center items-center px-4 py-2 text-sm bg-white text-black hover:bg-[#fce300]  "
                          )}>
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/app/dashboard" className="-m-1.5 p-1.5">
                <span className="sr-only">Ditto</span>
                <img
                  className=" animate-bounce-slow h-8 w-auto"
                  src={iconLogo}
                  alt="Ditto logo"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:text-[#fce300]"
                onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {/* <Disclosure as="div" className="-mx-3">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Product
                      <ChevronDownIcon
                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-2 space-y-2">
                      {[...products, ...callsToAction].map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure> */}
                  {HeadersMenu.map((item, index) => (
                    <div key={index} className=" block my-4 mx-1">
                      <Link
                        to={item.href}
                        className={`text-sm font-semibold leading-6 ${
                          location.pathname === item.href ||
                          location.pathname.includes(item.href)
                            ? "text-[#fce300] hover:text-gray-900"
                            : "text-gray-900 hover:text-[#fce300]"
                        } `}>
                        {item.title}
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="py-6">
                  {!isLoginUser && Object.keys(user).length === 0 ? (
                    <div>
                      {AuthHeader.map((headerItem, index) => (
                        <div key={index} className=" block my-4 mx-1">
                          <Link
                            to={headerItem.href}
                            className={`text-sm font-semibold leading-6  ${
                              headerItem.href === location.pathname
                                ? "text-[#fce300] hover:text-gray-900 "
                                : "text-gray-900} hover:text-[#fce300]"
                            } `}>
                            {headerItem.title}
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex rounded-full p-2 text-bold bg-[#fce300] text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#fce300] hover:bg-transparent hover:text-[#fce300]">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <h3 className=" font-bold">
                            {user && user.fullName}
                          </h3>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute  max-[768px]:left-0 right-0 z-10 mt-2 w-24 origin-top-right rounded-md text-[#fce300] hover:text-white hover:bg-[#fce300] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={(e) => handleUserLogOut()}
                                className={classNames(
                                  active ? "" : "",
                                  "flex justify-center items-center px-4 py-2 text-sm "
                                )}>
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
};

export default Headers;
