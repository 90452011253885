import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const RootApp = ({children}) => {
              const location =useLocation();
              const navigate=useNavigate();
              useEffect(()=>{
                            if(location.pathname === "/"){
                                          navigate("/app/",{replace:true})
                            }
              },[location,navigate])
  return (
    <>
    {children}
              <Outlet />
    </>
  )
}

export default RootApp