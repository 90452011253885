import React, { Fragment, useEffect, useState } from "react";
import Footer from "../../common/Footer";
import { useForm } from "react-hook-form";
import { requestApi } from "../../requestApiCall/Request";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { notify } from "../../common/Notify";
import Addsizefiled from "./Addsizefiled";
import Loadershow from "../../common/Loadershow";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoginUser, selectUserInfo } from "../../auth/authSlice";
import {
  addDataRecordAsync,
  resetAddDataRecordAsync,
  selectErrorAddData,
  selectIsLoading,
  selectIsRedirectTab,
} from "./addDataSlice/addDataSlice";
import {
  selectBrandOption,
  selectFabricOption,
  selectProductTypeOption,
  selectPrintableOptions,
} from "../../app/Constaint";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { DocumentPlusIcon, DocumentCheckIcon } from "@heroicons/react/24/solid";
import { renameFile, newFileName } from "../../common/Processhelper";
import {
  addPatternsField,
  UploadImageUI,
  UploadPdfUI,
  InputField,
  SelectField,
  addPatternsFieldImage,
  addPatternsFieldPDF,
} from "./common/CommonUI";
const prepareVariation = {
  Piece_Number: "",
  pattern_view: "",
  Piece_Description: "",
  Fabric_Category: "",
  Cut_Quantity: "",
  Fold: "",
  Patterns_SVG: "",
  Contrast: "",
};

const prepareSplicing = {
  Size: "",
  pattern_view: "",
  Design_id: "",
  Mannequin_id: "",
  Garment_45: "",
  Garment_55: "",
  Garment_60: "",
  Lining_45: "",
  Lining_55: "",
  Lining_60: "",
  Interfacing_20: "",
  Interfacing_45: "",
  Other_20: "",
  Other_45: "",
  Prod_Status: "",
  Staging_Status: "",
  PreProd_Status: "",
  Error_Prod: "",
  Error_Stg: "",
  Error_PreProd: "",
  // View:'' ,
  // PDF:''// [{...prepareVariation}]
};

const defaultValues = {
  Size: [
    {
      name: "Size",
      // View:''// [{ ...prepareVariation }]
    },
  ],
};

const Adddatarecords = () => {
  // const [selectPrintable,setSelectPrintable]=useState("default");
  // const [addSizeTable,setAddSizeTable]=useState([{...prepareSplicing}]);
  // const [showLoader,setShowLoader]=useState(false);
  const [collapseTabs, isSetCollapse] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const location = useLocation();
  const isLoginUser = useSelector(selectIsLoginUser);
  const isLoading = useSelector(selectIsLoading);
  const isRedirectTab = useSelector(selectIsRedirectTab);
  const apiError = useSelector(selectErrorAddData);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    if (Object.keys(errors).length) {
      let newCollapse = {
        step1: true,
        step2: true,
        step3: true,
        step4: true,
      };
      isSetCollapse({ ...newCollapse });
      notify.info("please check all required fields");
    }
  }, [errors]);
  useEffect(() => {
    // reset();
    if (Object.keys(user).length > 0 && isLoginUser) {
      navigate(
        location.pathname !== "/app/login"
          ? location.pathname
          : "/app/dashboard",
        { replace: true }
      );
    }
    // dispatch()
  }, [user, isLoginUser]);

  useEffect(() => {
    dispatch(resetAddDataRecordAsync());
  }, [dispatch]);
  const navigate = useNavigate();
  const onFormSubmit = (data) => {
    // console.log(data);
    // setShowLoader(true);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const formData = new FormData();
    let sizeFlag = false;
    const filesArray = new Array();

    for (let i = 0; i < data.Size.length; i++) {
      if (
        data.Size[i].pattern_pdf_file &&
        data.Size[i].pattern_pdf_file.length &&
        Math.round(data.Size[i].pattern_pdf_file[0].size / 1024) > 5120
      ) {
        sizeFlag = true;
        break;
      }
      // console.log(data.Size[i].pattern_pdf_file[0])

      let newFileCom = renameFile(
        data.Size[i].pattern_pdf_file[0],
        `pattern_pdf_file${i}`
      );
      // const blob = new Blob(data.Size[i].pattern_pdf_file[0],{type:"application/pdf"})
      delete data.Size[i].pattern_pdf_file;
      const fileDetails = newFileCom;
      // formData.append(`files`,fileDetails ,fileDetails.name);
      filesArray.push(newFileCom);
      formData.append(`files`, fileDetails);
      data.Size[i].Pattern_Pdf_File_Name = fileDetails.name;
      delete data.Size[i].pattern_pdf_file;
    }
    if (sizeFlag) {
      notify.warning("All pdf file should be less than 5MB");
      return false;
    }
    // image should be less than 10MB (1024* 10 )
    if ("Hero_Images" in data && data.Hero_Images.length) {
      for (let j = 0; j < data.Hero_Images.length; j++) {
        if (Math.round(data.Hero_Images[j].size / 1024) > 10240) {
          sizeFlag = true;
          break;
        }
        let newHeroFile = renameFile(data.Hero_Images[j], `Hero_Images_${j}`);
        formData.append(`Hero_Images`, newHeroFile);
      }
    }
    if ("Main_Hero_Image" in data && data.Main_Hero_Image.length) {
      if (Math.round(data?.Main_Hero_Image[0].size / 1024) > 10240) {
        sizeFlag = true;
      }

      let mainFileImg = renameFile(data.Main_Hero_Image[0], `Main_Hero_Image`);
      formData.append(`Main_Hero_Image`, mainFileImg);
    }
    if (
      data.hasOwnProperty("Yardage_PDF") &&
      data.Yardage_PDF !== undefined &&
      data.Yardage_PDF !== null &&
      data.Yardage_PDF.length
    ) {
      let Yardage_PDF_File = renameFile(data.Yardage_PDF[0], `Yardage_PDF`);
      formData.append(`Yardage_PDF`, Yardage_PDF_File);
      delete data.Yardage_PDF;
    }
    //Sketch_Image
    if (
      data.hasOwnProperty("Size_Chart") &&
      data.Size_Chart !== undefined &&
      data.Size_Chart !== null &&
      data.Size_Chart.length
    ) {
      let Size_Chart_File = renameFile(data.Size_Chart[0], `Size_Chart`);
      formData.append(`Size_Chart`, Size_Chart_File);
      delete data.Size_Chart;
    }
    if (data.hasOwnProperty("Yardage_Image") && data.Yardage_Image.length) {
      let Yardage_Image_File = renameFile(
        data.Yardage_Image[0],
        `Yardage_Image`
      );
      formData.append(`Yardage_Image`, Yardage_Image_File);

      if (Math.round(data.Yardage_Image[0].size / 1024) > 10240) {
        sizeFlag = true;
      }
    }
    if (
      data.hasOwnProperty("Sketch_Image") &&
      data.Sketch_Image !== undefined &&
      data.Sketch_Image !== null &&
      data.Sketch_Image.length
    ) {
      let Sketch_Image_File = renameFile(data.Sketch_Image[0], `Sketch_Image`);
      formData.append(`Sketch_Image`, Sketch_Image_File);
      delete data.Sketch_Image;
    }
    if (
      data.hasOwnProperty("Instruction_PDF") &&
      data.Instruction_PDF !== undefined &&
      data.Instruction_PDF !== null &&
      data.Instruction_PDF.length
    ) {
      let Instruction_PDF_File = renameFile(
        data.Instruction_PDF[0],
        `Instruction_PDF`
      );
      formData.append(`Instruction_PDF`, Instruction_PDF_File);
      delete data.Instruction_PDF;
    }
    if (sizeFlag) {
      notify.info("All Images should be less than 3MB ");
      return false;
    }
    delete data?.Yardage_Image;
    delete data?.Main_Hero_Image;
    delete data?.Hero_Images;
    delete data?.Sketch_Image;
    delete data?.Size_Chart;
    delete data?.Instruction_PDF;
    delete data?.Yardage_PDF;

    if (
      !data.hasOwnProperty("Brand") ||
      ("Brand" in data && data.Brand === undefined)
    )
      data = { ...data, Brand: user.brand };
    // delete data.Size[0].pattern_pdf_file;
    // delete data.Size[1].pattern_pdf_file;

    formData.append("data", JSON.stringify(data));
    // formData.append("files",filesArray);
    // formData.append(`files`, files);
    // data.files=files;
    // console.log(formData);
    // console.log(data);
    // sendDataFetchApi(formData);
    dispatch(
      addDataRecordAsync({
        data: data,
        files: filesArray,
        fileFormData: formData,
        token: user.token,
      })
    );
  };
  const handleCollapse = (e, keyName) => {
    switch (keyName) {
      case "step1":
        isSetCollapse({ ...collapseTabs, step1: !collapseTabs.step1 });
        break;
      case "step2":
        isSetCollapse({ ...collapseTabs, step2: !collapseTabs.step2 });
        break;
      case "step3":
        isSetCollapse({ ...collapseTabs, step3: !collapseTabs.step3 });
        break;
      case "step4":
        isSetCollapse({ ...collapseTabs, step4: !collapseTabs.step4 });
        break;
      default:
        break;
    }
  };
  return (
    <>
      {Object.keys(user).length === 0 && !isLoginUser && (
        <Navigate to={"/app/login"} replace={true} />
      )}
      {/* {apiError && apiError !== null && notify.error(apiError.message)} */}
      {isRedirectTab && <Navigate to={"/app/show-records"} replace={true} />}
      <div
        className={`justify-center items-center relative flex flex-col isolate  px-6 py-24 sm:py-20 lg:px-8 ${
          isLoading ? "opacity-60" : ""
        }`}>
        {isLoading && <Loadershow show={isLoading} />}
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto w-full text-center mb-10">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Pattern Submission Form
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Please provide the customer-facing copy, data, and images for this
            pattern.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="bg-white border drop-shadow-2xl border-[#fce300] rounded-lg relative shadow-2xl shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300]  flex  flex-1 flex-col justify-center p-6 lg:px-8 sm:mx-auto w-8/12"
          encType="multipart/form-data"
          method="POST">
          <div className="space-y-12 w-full sticky h-auto  border-b border-[#fce300] scrollbar-thin scrollbar-thumb-[#fce300] scrollbar-thumb-rounded-lg scrollbar-track-rounded-full p-2 scrollbar-track-slate-50">
            <div className="border-b border-[#fce300] pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Pattern Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Please fill all required fields. Click a pattern on our website
                to see where this information appears.
              </p>

              <div className="mt-4">
                <div className="drop-shadow-2xl border-[#fce300] border-2 p-3 rounded-xl ease-in duration-[1200ms] transition-all my-3 cursor-pointer">
                  <div
                    onClick={(e) => handleCollapse(e, "step1")}
                    className="rounded-xl cursor-pointer col-span-full text-center  flex flex-row justify-start items-center relative">
                    <h2>Basic Pattern Details</h2>
                    <span className=" absolute right-5 ease-in duration-[1200ms] transition-all">
                      {collapseTabs.step1 ? (
                        <ChevronUpIcon className="w-6 h-6 " />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 " />
                      )}
                    </span>
                  </div>
                  <div
                    className={`ease-in  transition-all grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ${
                      collapseTabs.step1
                        ? " visible h-auto mt-5 duration-[1200ms]  "
                        : " invisible h-0 duration-[200ms]"
                    }`}>
                    {addPatternsField &&
                      addPatternsField.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.isSelectField ? (
                            <>
                              <SelectField
                                {...item}
                                watch={watch}
                                register={register}
                                errors={errors}
                              />
                            </>
                          ) : (
                            <InputField
                              {...item}
                              watch={watch}
                              register={register}
                              errors={errors}
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="border-[#fce300] border-2 p-3 rounded-xl ease-in duration-[1200ms] transition-all my-3 cursor-pointer">
                  <div
                    onClick={(e) => handleCollapse(e, "step2")}
                    className="rounded-xl cursor-pointer col-span-full text-center  flex flex-row justify-start items-center relative">
                    <h2>PDF's</h2>
                    <span className=" absolute right-5 ease-in duration-[1200ms] transition-all">
                      {collapseTabs.step2 ? (
                        <ChevronUpIcon className="w-6 h-6 " />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 " />
                      )}
                    </span>
                  </div>

                  <div
                    className={`ease-in transition-all grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ${
                      collapseTabs.step2
                        ? " visible h-auto mt-5 duration-[1200ms]"
                        : " invisible h-0 duration-[100ms]"
                    }`}>
                    {addPatternsFieldPDF &&
                      addPatternsFieldPDF.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.isPDF && (
                            <>
                              <UploadPdfUI
                                {...item}
                                watch={watch}
                                register={register}
                                errors={errors}
                              />
                            </>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="border-[#fce300] border-2 p-3 rounded-xl ease-in duration-[1200ms] transition-all my-3 cursor-pointer">
                  <div
                    onClick={(e) => handleCollapse(e, "step3")}
                    className="rounded-xl cursor-pointer col-span-full text-center  flex flex-row justify-start items-center relative">
                    <h2>Images</h2>
                    <span className=" absolute right-5 ease-in duration-[1200ms] transition-all">
                      {collapseTabs.step3 ? (
                        <ChevronUpIcon className="w-6 h-6 " />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 " />
                      )}
                    </span>
                  </div>
                  <div
                    className={`ease-in  transition-all grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ${
                      collapseTabs.step3
                        ? "visible h-auto mt-5 duration-[1200ms]"
                        : " invisible h-0 mt-0 duration-[100ms]"
                    }`}>
                    {addPatternsFieldImage &&
                      addPatternsFieldImage.map((item, index) => (
                        <React.Fragment key={index}>
                          <>
                            <UploadImageUI
                              {...item}
                              watch={watch}
                              register={register}
                              errors={errors}
                            />
                          </>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="border-[#fce300] border-2 p-3 rounded-xl ease-in duration-[1200ms] transition-all cursor-pointer">
                  <div
                    onClick={(e) => handleCollapse(e, "step4")}
                    className="rounded-xl cursor-pointer col-span-full text-center  flex flex-row justify-start items-center relative">
                    <h2>Pattern Size</h2>
                    <span className=" absolute right-5 ease-in duration-[1200ms] transition-all">
                      {collapseTabs.step4 ? (
                        <ChevronUpIcon className="w-6 h-6 " />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 " />
                      )}
                    </span>
                  </div>
                  <div
                    className={`ease-in transition-all col-span-full${
                      collapseTabs.step4
                        ? " visible h-auto mt-5 duration-[1200ms]"
                        : " invisible h-0 mt-0 duration-[100ms]"
                    }`}>
                    {/* <p className=" text-center text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
                    Pattern Size
                  </p> */}
                    <Addsizefiled
                      {...{
                        control,
                        register,
                        defaultValues,
                        getValues,
                        setValue,
                        errors,
                        prepareSplicing,
                        prepareVariation,
                        clearErrors,
                        watch,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="w-80 mt-3 px-12 py-3 inline-flex justify-center rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-[#fce300] hover:bg-[#fce300] sm:mt-0 ">
              Cancel
            </button>
            <button
              type="submit"
              className="w-80 rounded-md bg-[#fce300] px-12 py-3 text-sm font-semibold text-gray-800 shadow-sm hover:bg-white hover:border-solid hover:border-2 hover:border-[#fce300] hover:py-2">
              Save
            </button>
          </div>
        </form>
      </div>
      <div className=" relative w-full mt-4">
        <Footer />
      </div>
    </>
  );
};

export default Adddatarecords;
