import React from 'react'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Add your patterns',
    description:
      'You can add your own pattern with flexible feature like edit ,delete,upload his pattern pdf ,Images and download your pattern with existing pattern details.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Multiple brand ',
    description:
      'You can see multiple brands patterns with own account inside the show-record section if you have admin access to see multiple brands patterns.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple show case your patterns',
    description:
      'You can show your own pattern with flexible feature like edit ,delete ,upload his pattern pdf and filter,sorting to find your patterns.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced filter & sorting features',
    description:
      'We provide a advance feature in table to easily find your pattern or sort your patterns,we provide more flexible features',
    icon: FingerPrintIcon,
  },
]
const AboutConnectingPhase = () => {
  return (
    <div className=" py-5 sm:py-10">
    <div className="mx-auto max-w-7xl px-2 lg:px-4">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-base font-semibold leading-7 text-[#fce300]">Advance Features</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to upload your pattern
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
        Check the accuracy of all fields and photos as they will appear to customers. When finished, confirm that the product is complete and ready to post
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-3xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-16">
              <dt className=" flex h-10 w-10 items-center gap-x-4 text-base font-semibold leading-7 text-gray-900">
                <div className="rounded-lg bg-[#fce300]">
                  <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                </div>
                <span>{feature.name}</span>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
  
  )
}

export default AboutConnectingPhase
