import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon ,InformationCircleIcon} from '@heroicons/react/24/outline'

export default function Termandpolicy({open,setOpen ,handleAcceptTerms}) {


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10"  onClose={(e)=>setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#fce300] sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon className="h-6 w-6 text-gray-800" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Term & Policy
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-700">
                        Privacy Policies are required by law to be posted on your website. You may be required to include specific clauses in your Privacy Policy, depending on the applicable laws within your area or where you are conducting business.
                        </p>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-700">
                        The FTC (Federal Trade Commission) regulates data protection for all consumers in the USA, and the following laws all have privacy implications:</p>
                      </div>
                      <div className="mt-2">
                        <ul className="text-sm text-[#fce300] list-disc">
                        <li>
                        <p className='text-gray-600'>The Americans With Disability Act</p>
                        </li>
                        <li>
                        <p className='text-gray-600'>The Consumer Credit Reporting Control Act</p>
                        </li>
                        <li>
                        <p  className='text-gray-600'>Oregon: Consumer Information Protection Act (OCIPA)</p>
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-[#fce300] px-3 py-2 text-sm font-semibold hover:text-gray-800 text-gray-800 shadow-sm hover:bg-white sm:ml-3 sm:w-auto ring-[#fce300]"
                    onClick={(e) => handleAcceptTerms(e,"accept")}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-[#fce300] hover:bg-[#fce300] sm:mt-0 sm:w-auto"
                    onClick={(e) => handleAcceptTerms(e,"reject")}
                  >
                    Reject
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
