import React from "react";
import { useFieldArray } from "react-hook-form";
import AddviewField from "./AddviewField";
import { notify } from "../../common/Notify";
import { DocumentPlusIcon, DocumentCheckIcon } from "@heroicons/react/24/solid";
const Addsizefiled = ({
  control,
  register,
  defaultValues,
  getValues,
  setValue,
  errors,
  prepareSplicing,
  prepareVariation,
  clearErrors,
  watch,
}) => {
  // const { fields, append, remove, prepend } = useFieldArray({
  //               control,
  //               name: "Size",
  //               View: { ...prepareVariation },
  // });
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "Size",
    View: "",
  });

  return (
    <>
      {fields.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ${
            index !== 0 ? "border-b border-[#fce300]" : ""
          }`}>
          <div className="sm:col-span-3">
            <label
              htmlFor="pattern_view"
              className="block text-sm font-medium leading-6 text-gray-900">
              Pattern View <span className="text-red-600">*</span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                {...register(`Size[${index}].pattern_view`, {
                  required: true,
                })}
                id={`pattern_view-${index}`}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
              />
            </div>
            {errors.Size &&
              errors.Size[index]?.pattern_view?.type === "required" && (
                <p role="alert" className="text-red-600">
                  <span className="text-red-600">- </span> Pattern View is
                  required
                </p>
              )}
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="Size"
              className="block text-sm font-medium leading-6 text-gray-900">
              Size <span className="text-red-600">*</span>
            </label>
            <div className="mt-2">
              <input
                type="text"
                {...register(`Size[${index}].Size`, {
                  required: true,
                })}
                id={`Size-${index}`}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
              />
            </div>
            {errors.Size && errors.Size[index]?.Size?.type === "required" && (
              <p role="alert" className="text-red-600">
                <span className="text-red-600">- </span> Size is required
              </p>
            )}
          </div>
          {/* <div className="sm:col-span-2">
                                                                                    <label
                                                                                                  htmlFor="Patterns_View"
                                                                                                  className="block text-sm font-medium leading-6 text-gray-900">
                                                                                                  Patterns View <span className="text-red-600">*</span>
                                                                                    </label>
                                                                                    <div className="mt-2">
                                                                                                  <input
                                                                                                                type="number"
                                                                                                                {...register(`Size[${index}].Patterns_View`, {
                                                                                                                              required: true,
                                                                                                                })}
                                                                                                                id={`Size-${index}`}
                                                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                                                                                                  />
                                                                                    </div>
                                                                                    {errors.Size && errors.Size[index]?.Patterns_View?.type === "required" && (
                                                                                                  <p role="alert" className="text-red-600">
                                                                                                                <span className="text-red-600">- </span> Patterns View is required
                                                                                                  </p>
                                                                                    )}
                                                                      </div> */}
          {/* <div className="sm:col-span-2">
                                                                                    <label
                                                                                                  htmlFor="Garment_60"
                                                                                                  className="block text-sm font-medium leading-6 text-gray-900">
                                                                                                  Garment 60 <span className="text-red-600">*</span>
                                                                                    </label>
                                                                                    <div className="mt-2">
                                                                                                  <input
                                                                                                                type="text"
                                                                                                                {...register(`Size[${index}].Garment_60`, {
                                                                                                                              required: true,
                                                                                                                })}
                                                                                                                id={"Garment_60" + index}
                                                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                                                                                                  />
                                                                                    </div>
                                                                                    {errors.Size &&
                                                                                                  errors.Size[index]?.Garment_60?.type === "required" && (
                                                                                                                <p role="alert" className="text-red-600">
                                                                                                                              <span className="text-red-600">- </span> Garment_60 is
                                                                                                                              required
                                                                                                                </p>
                                                                                                  )}
                                                                      </div> */}
          {/* <div className="sm:col-span-3">
                                                                                    <label
                                                                                                  htmlFor="Interfacing_45"
                                                                                                  className="block text-sm font-medium leading-6 text-gray-900">
                                                                                                  Interfacing 45 <span className="text-red-600">*</span>
                                                                                    </label>
                                                                                    <div className="mt-2">
                                                                                                  <input
                                                                                                                type="text"
                                                                                                                {...register(`Size[${index}].Interfacing_45`, {
                                                                                                                              required: true,
                                                                                                                })}
                                                                                                                id={"Interfacing_45" + index}
                                                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                                                                                                  />
                                                                                    </div>
                                                                                    {errors.Size &&
                                                                                                  errors.Size[index]?.Interfacing_45?.type === "required" && (
                                                                                                                <p role="alert" className="text-red-600">
                                                                                                                              <span className="text-red-600">- </span> Interfacing_45 is
                                                                                                                              required
                                                                                                                </p>
                                                                                                  )}
                                                                      </div>
                                                                      <div className="sm:col-span-3">
                                                                                    <label
                                                                                                  htmlFor="Design_id"
                                                                                                  className="block text-sm font-medium leading-6 text-gray-900">
                                                                                                  Design Id<span className="text-red-600">*</span>
                                                                                    </label>
                                                                                    <div className="mt-2">
                                                                                                  <input
                                                                                                                type="text"
                                                                                                                {...register(`Size[${index}].Design_id`, {
                                                                                                                              required: true,
                                                                                                                })}
                                                                                                                id={"Design_id" + index}
                                                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                                                                                                  />
                                                                                    </div>
                                                                                    {errors.Size &&
                                                                                                  errors.Size[index]?.Design_id?.type === "required" && (
                                                                                                                <p role="alert" className="text-red-600">
                                                                                                                              <span className="text-red-600">- </span> Design_id is required
                                                                                                                </p>
                                                                                                  )}
                                                                      </div>

                                                                      <div className="sm:col-span-3">
                                                                                    <label
                                                                                                  htmlFor="Mannequin_id"
                                                                                                  className="block text-sm font-medium leading-6 text-gray-900">
                                                                                                  Mannequin Id <span className="text-red-600">*</span>
                                                                                    </label>
                                                                                    <div className="mt-2">
                                                                                                  <input
                                                                                                                type="text"
                                                                                                                {...register(`Size[${index}].Mannequin_id`, {
                                                                                                                              required: true,
                                                                                                                })}
                                                                                                                id={"Mannequin_id" + index}
                                                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                                                                                                  />
                                                                                    </div>
                                                                                    {errors.Size &&
                                                                                                  errors.Size[index]?.Mannequin_id?.type === "required" && (
                                                                                                                <p role="alert" className="text-red-600">
                                                                                                                              <span className="text-red-600">- </span> Mannequin_id is
                                                                                                                              required
                                                                                                                </p>
                                                                                                  )}
                                                                      </div> */}

          {/* <div className="col-span-full border-b border-[#fce300] py-4"></div> */}
          {/* <div className="col-span-full">
                                                                                    <p className="mt-1 w-full text-xl leading-6 text-gray-600 text-center">
                                                                                                  {" "}
                                                                                                  Pattern Piece{" "}
                                                                                    </p>
                                                                      </div> */}
          {/* <AddviewField
                                                                                    nestIndex={index}
                                                                                    {...{
                                                                                                  control,
                                                                                                  register,
                                                                                                  prepareSplicing,
                                                                                                  errors,
                                                                                                  prepareVariation,
                                                                                                  clearErrors,
                                                                                    }}
                                                                      /> */}
          {/* <div className="col-span-full border-b border-[#fce300] py-4"></div> */}
          {/* <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> */}
          <div className="col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900">
              Upload Pattern PDF<span className="text-red-600">*</span>
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <input
                id={`Size[${index}].pattern_pdf_file`}
                {...register(`Size[${index}].pattern_pdf_file`, {
                  required: true,
                })}
                type="file"
                className="sr-only"
                accept="application/pdf"
              />
              <div className="text-center justify-center items-center flex-col flex gap-3">
                {/* {console.log(watch(`Size[${index}].pattern_pdf_file`))} */}
                {!watch(`Size[${index}].pattern_pdf_file`) ||
                watch(`Size[${index}].pattern_pdf_file`).length === 0 ? (
                  <>
                    <DocumentPlusIcon
                      className="mx-auto h-12 w-12 text-[#fce300]"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  <>
                    <strong className="text-gray-900">
                      {!watch(`Size[${index}].pattern_pdf_file`)[0]?.name
                        ? null
                        : watch(`Size[${index}].pattern_pdf_file`)[0]?.name}
                    </strong>{" "}
                    <DocumentCheckIcon
                      className="mx-auto h-12 w-12 text-[#fce300]"
                      aria-hidden="true"
                    />
                  </>
                )}

                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor={`Size[${index}].pattern_pdf_file`}
                    className="relative cursor-pointer rounded-md bg-white font-semibold  focus-within:outline-none focus-within:ring-1 focus-within:ring-[#fce300] focus-within:ring-offset-2 hover:text-[#fce300]">
                    <span>UPLOAD</span>
                  </label>
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                <p className="text-xs leading-5 text-[#fce300]">
                  PDF file up to 5 MB
                </p>
              </div>
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">
              One pdf maximum
            </p>
            {errors?.Size &&
              errors?.Size[index]?.pattern_pdf_file?.type === "required" && (
                <p role="alert" className="text-red-600">
                  <span className="text-red-600">- </span>
                  Patterns pdf is required
                </p>
              )}
          </div>
          {/* </div> */}
          <div className="flex items-center justify-end my-5 col-span-full ">
            {fields.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  remove(index);
                  notify.success("Successfully Delete Size ");
                  clearErrors();
                }}
                className="text-sm font-semibold leading-6 text-[#fce300]">
                Delete Size
              </button>
            )}
            {index === fields.length - 1 && (
              <button
                type="button"
                onClick={() => {
                  clearErrors();
                  setValue("Size", [
                    ...(getValues().Size || []),
                    {
                      name: "Size",
                      // View: '',
                    },
                  ]);
                  notify.success("Successfully Added Size");
                }}
                className=" bg-[#fce300] ml-3 rounded-md px-5 py-1 text-sm font-semibold text-gray-800 shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                Add Size
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default Addsizefiled;

// onClick={() => {
//               clearErrors();
//               setValue("Size", [
//                             ...(getValues().Size || []),
//                             {
//                                           name: "Size",
//                                           View: [{ ...prepareVariation }],
//                             },
//               ]);
//               notify.success("Successfully Added Size");
// }}
