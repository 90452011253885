export const addDataRecord =async ({data,fileFormData,files,token})=>{
              return new Promise(async (resolve, reject) => {
                            try {
                                          const bearer = 'Bearer ' + token;
                                          const sendFile =await fetch('/api/patternRecord/addPatternsRecord',{
                                                        method:"POST",
                                                        body:fileFormData,
                                                        headers:{
                                                                      'Authorization': bearer,
                                                        }
                                          })
                                          if(sendFile.ok){
                                                        const data = await sendFile.json();
                                                        resolve({ data });
                                          }
                                          else{
                                                        const error = await sendFile.json();
                                                        reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}