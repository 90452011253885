/* eslint-disable no-sparse-arrays */
import React, { Suspense, lazy } from 'react';

import Loadershow from "../features/common/Loadershow";
import { createBrowserRouter } from "react-router-dom";
import Errorpages from "../features/components/Pages/Errorpages";
import Dashboard from "../features/components/Pages/Dashboard";

import Adddatarecords from "../features/components/Datatable/Adddatarecords";
import Contactsupport from "../features/components/Pages/Contactsupport";
import About from "../features/components/Pages/About";
import Headers from "../features/common/Headers";
// import Termandpolicy from "../features/components/Pages/Termandpolicy";
import Root from '../features/components/Pages/Root';
import RootApp from '../features/components/Pages/RootApp';
import Login from '../features/components/Pages/Login';
import SignupPage from '../features/components/Pages/SignupPage';
import ForgotPassword from '../features/components/Pages/ForgotPassword';
import Resetpassword from '../features/components/Pages/Resetpassword';
import Signout from '../features/auth/components/Signout';
const Datatable = lazy(() => import("../features/components/Datatable/Datatable"));
const DataTableSize = lazy(() => import("../features/components/Datatable/DataTableSize"));
const DataTableView = lazy(() => import("../features/components/Datatable/DataTableView"));
const Account = lazy(() => import("../features/auth/components/Account"));
export const routers = createBrowserRouter([
  {
    path: "/",
    element: <><RootApp /></>,
    children: [
      {
        path: "/app",
        element: <><Root /> </>,
        errorElement: <Errorpages />,
        children: [
          {
            path: "dashboard",
            element: <><Headers /><Dashboard /></>,
          }
          ,
          {
            path: "login",
            element: <><Headers /><Login /></>,
          }
          ,
          {
            path: "signup",
            element: <><Headers /><SignupPage /></>,
          }
          ,
          {
            path: "forgot-password",
            element: <><Headers /><ForgotPassword /></>,
          }
          ,
          {
            path: "reset-password",
            element: <><Headers /><Resetpassword /></>,
          },
          {
            path: "signout",
            element: <><Signout /> </>,
          }
          ,
          {
            path: "show-records",
            element: <><Headers /><Suspense fallback={<Loadershow show={true} />
            } >
              <Datatable />
            </Suspense></>
            ,
          },
          {
            path: "my-account",
            element: <><Headers />
              <Suspense fallback={<Loadershow show={true} />} >
                <Account />
              </Suspense></>
            ,
          },
          {
            path: "add-record",
            element: <><Headers /><Adddatarecords /></>,
          },
          {
            path: "contact-support",
            element: <><Headers /><Contactsupport /></>,
          },
          {
            path: "about",
            element: <><Headers /><About /></>,
          },
          {
            path: "show-records/:ID",
            element: <><Headers />
              <Suspense fallback={<Loadershow show={true} />}>
                <DataTableSize />
              </Suspense></>
          },
          ,
          {
            path: "show-records/:ID/:Size",
            element: <><Headers />
              <Suspense fallback={<Loadershow show={true} />}>
                <DataTableView />
              </Suspense></>
          },
          {
            path: "*",
            element: <><Errorpages /></>
          }
        ]
      },
      {
        path: "*",
        element: <><Errorpages /></>
      }
    ]
  }

])
