import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { addDataRecord } from './addDataApi';
import { notify } from '../../../common/Notify';
export const addDataRecordAsync = createAsyncThunk(
              'pattern/addDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await addDataRecord(payload);
                            notify.success("Successfully Data has been added")
                            return response.data;
                            } catch (error) {
                            // console.log(error);
                            if(error.message){
                                          notify.error(error.message)
                            }
                            else{
                                          notify.error(JSON.stringify(error));
                            }
                            return rejectWithValue(error);
                            }
                            }
              );
export const resetAddDataRecordAsync = createAsyncThunk(
                            'pattern/resetAddDataRecord',
                            async (payload, { rejectWithValue }) => {
                                          return true;
                                          // try {
                                          // const response = await addDataRecord(payload);
                                          // return response.data;
                                          // } catch (error) {
                                          // console.log(error);
                                          // return rejectWithValue(error);
                                          // }
                                          }
                            );

export const addDataRecordSlice = createSlice({
              name: 'addDataRecord',
              initialState: {
              value: 0,
              data:[],
              isFetchedData:false,
              isLoading:false,
              error:null,
              isRedirect:false
              },
              reducers: {
              
              },
              extraReducers: (builder) => {
                            builder
                            .addCase(addDataRecordAsync.pending, (state) => {
                            state.status = 'loading';
                            state.isLoading=true;
                            })
                            .addCase(addDataRecordAsync.fulfilled, (state, action) => {
                            state.status = 'idle';
                            console.log(action);
                            state.isRedirect=action.payload.success ? true :false;
                            state.data = action.payload;
                            state.error=null;
                            state.isLoading=false;
                            })
                            .addCase(addDataRecordAsync.rejected, (state, action) => {
                            state.status = 'idle';
                            state.error = action.payload;
                            state.isLoading=false;
                            state.isRedirect=false;
                            })
                            .addCase(resetAddDataRecordAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
                            })
                            .addCase(resetAddDataRecordAsync.fulfilled,(state,action)=>{
                            state.status = 'idle';
                            state.error = null;
                            state.data=[];
                            state.isLoading=false;
                            state.isRedirect=false;
                            })

              }
})

// Action creators are generated for each case reducer function
export const selectDataRecord = (state) => state.addDataRecord.data;
export const selectIsFetchedData = (state) => state.addDataRecord.isFetchedData;
export const selectIsLoading =(state)=> state.addDataRecord.isLoading;
export const selectIsRedirectTab =(state)=>state.addDataRecord.isRedirect;
export const selectErrorAddData =(state)=>state.addDataRecord.error;

export default addDataRecordSlice.reducer