import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { deleteDataRecord, getDataRecord, updateDataRecord,updateSizeDataRecord,patternPublish } from './getDataRecordApi';
import { globalState } from '../../../app/globalState';
import { notify } from '../../../common/Notify';


const initialState={
              totalCountRecord: 0,
              dataRecord:[],
              sizeDataRecord:[],
              totalCountSizeRecord:0,
              error:null,
              isFetchedData:false,
              isLoading:false,
              isRedirect:false,
              isDelete:false
              };
export const getDataRecordAsync = createAsyncThunk(
              'pattern/getDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await getDataRecord(payload);
                            return response.data;
                            } catch (error) {
                            return rejectWithValue(error);
                            }
                            }
);
export const deleteMultipleRowsRecordAsync = createAsyncThunk(
  'pattern/deleteMultipleRowsRecord',
  async (payload, { rejectWithValue }) => {
                try {
                const response = await deleteDataRecord(payload);
                return response.data;
                } catch (error) {
                return rejectWithValue(error);
                }
                }
);
export const updateMainDataRecordAsync = createAsyncThunk(
              'pattern/updateMainDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await updateDataRecord(payload);
                            return response.data;
                            } catch (error) {
                            return rejectWithValue(error);
                            }
                            }
);
export const deleteMainDataRecordAsync = createAsyncThunk(
              'pattern/deleteMainDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await deleteDataRecord(payload);
                            notify.success("Successfully Data Deleted");
                            return response.data;
                            } catch (error) {
                            notify.success("Unable to delete data");
                            return rejectWithValue(error);
                            }
                            }
);
export const getSizeDataRecordAsync = createAsyncThunk(
              'pattern/getSizeDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await getDataRecord(payload);
                            return response.data;
                            } catch (error) {
                            return rejectWithValue(error);
                            }
                            }
);
export const updateSizeDataRecordAsync = createAsyncThunk(
              'pattern/updateSizeDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await updateSizeDataRecord(payload);
                            notify.success('message' in response?.data && response?.data.message ? response?.data?.message :"Successfully Update Record");
                            return response.data;
                            } catch (error) {
                            notify.error('message' in error && error.message ? error.message :"Failed Update Record");
                            return rejectWithValue(error);
                            }
                            }
);
export const addNewSizeDataRecordAsync = createAsyncThunk(
  'pattern/addNewSizeDataRecord',
  async (payload, { rejectWithValue }) => {
                try {
                const response = await updateSizeDataRecord(payload);
                notify.success('message' in response?.data && response?.data.message ? response?.data?.message :"Successfully Update Record");
                return response.data;
                } catch (error) {
                notify.error('message' in error && error.message ? error.message :"Failed Update Record");
                return rejectWithValue(error);
                }
                }
);
export const patternPublishAsync = createAsyncThunk(
              'pattern/patternPublish',
              async (payload, { rejectWithValue }) => {
                            try {
                            // console.log(payload);
                            const response = await patternPublish(payload);
                            return response.data;

                            } catch ( error )
                            {
                            notify.error('message' in error && error.message ? error.message :"Failed to review pattern");
                            return rejectWithValue(error);
                            }
                            }
);

export const patternPublishToProductionAsync = createAsyncThunk(
              'pattern/patternPublishToProduction',
              async (payload, { rejectWithValue }) => {
                            try {
                            // console.log(payload);
                            const response = await patternPublish(payload);
                            notify.success('message' in response?.data && response?.data?.message ? response?.data?.message :"Successfully ready to publish on Production");
                            return response.data;

                            } catch (error) {
                            notify.error('message' in error && error.message ? error.message :"Failed to publish pattern on Production");
                            return rejectWithValue(error);
                            }
                            }
);
export const deleteSizeDataRecordAsync = createAsyncThunk(
              'pattern/deleteSizeDataRecord',
              async (payload, { rejectWithValue }) => {
                            try {
                            const response = await deleteDataRecord(payload);
                            notify.success("Successfully Data Deleted");
                            return response.data;
                            } catch (error) {
                            notify.success("Unable to delete data");
                            return rejectWithValue(error);
                            }
                            }
);
export const resetRedirectAsync=createAsyncThunk('pattern/resetRedirect',async(payload, { rejectWithValue })=>{
                            return true;
})

export const getDataSlice = createSlice({
              name: 'getDataRecord',
              initialState,
              reducers: {
              
              },
              extraReducers: (builder) => {
                            builder
                            .addCase(getDataRecordAsync.pending, (state) => {
                            state.status = 'loading';
                            state.isLoading=true;

                            })
                            .addCase(getDataRecordAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                          state.dataRecord = action.payload.data;
                                          state.totalCountRecord=action.payload.totalCountData;
                                          state.error =null;
                                          state.isLoading=false;
                                          state.isRedirect=false;
                                          state.isDelete=false;
                            })
                            .addCase(getDataRecordAsync.rejected, (state, action) => {
                                          state.status = 'idle';
                                          state.isLoading=false;
                                          state.dataRecord = [];
                                          state.totalCountRecord=0;
                                          state.isDelete=false;
                                          state.error = action.payload.message || "Failed to fetch";
                                          state.isRedirect = action.payload?.isRedirected ? true : false;
                                          if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                        globalState.removeLocalStorage("user_authenticated");
                                                        globalState.removeLocalStorage("user");
                                          }
                                          
                            })
                            .addCase(patternPublishAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
              
                                          })
                            .addCase(patternPublishAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                                        if('data' in action.payload){
                                                                      let responseData = action.payload.data;
                                                                      let table = action.meta.arg?.table;
                                                                      let newRecord = [...state.dataRecord]
                                                                      let findIndex=newRecord.findIndex(item=> item.ID === responseData.ID)
                                                                      newRecord[findIndex].IS_SFCC_TESTING=responseData.IS_SFCC_TESTING;
                                                                      state.dataRecord=newRecord
                                                                      table.setEditingRow( null );
                                                                      if ( action?.meta?.arg )
                                                                      {
                                                                                    let args = action.meta.arg;
                                                                                    args?.setShowModalPublish( false );
                                                                                    args?.setSelectedRowDataForPublish( {} );

                                                                      }
                                                                      notify.success('message' in action.payload && action.payload.message ? action.payload.message :"Successfully publish to review pattern");
                                                        }
                                                        // state.sizeDataRecord = action.payload.data;
                                                        // state.totalCountSizeRecord=action.payload.totalCountData;
                                                        state.error =null;
                                                        state.isLoading=false;
                                                        state.isRedirect=false;
                                                        state.isDelete=false;
                                          })
                            .addCase(patternPublishAsync.rejected, (state, action) => {
                                                        state.status = 'idle';
                                          state.isLoading = false;
                                          // console.log( vaction );
                                                        // state.sizeDataRecord = [];
                                                        // state.totalCountSizeRecord=0;
                                          state.isDelete = false;
                                          if ( action?.meta?.arg )
                                                                      {
                                                                                    let args = action.meta.arg;
                                                                                    args?.setShowModalPublish( false );
                                                                                    args?.setSelectedRowDataForPublish( {} );

                                                                      }
                                                        // state.error = action.payload.message || "Failed to fetch";
                                                        state.isRedirect = action.payload?.isRedirected ? true : false;
                                                        if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                      globalState.removeLocalStorage("user_authenticated");
                                                                      globalState.removeLocalStorage("user");
                                                        }
                                                        
                            } )
                            .addCase(patternPublishToProductionAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
              
                                          })
                            .addCase(patternPublishToProductionAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                          console.log(action)
                                                        if('data' in action.payload){
                                                                      let responseData = action.payload.data;
                                                                      let table = action.meta?.arg?.table;
                                                                      let newRecord = [...state.dataRecord]
                                                                      let findIndex=newRecord.findIndex(item=> item.ID === responseData.ID)
                                                                      newRecord[findIndex].SFCC_Status=responseData.SFCC_Status;
                                                                      state.dataRecord=newRecord
                                                                      table.setEditingRow( null );
                                                                      if ( action?.meta?.arg )
                                                                      {
                                                                                    let args = action.meta.arg;
                                                                                    args?.setShowModal( false );
                                                                                    args?.setSelectedRowDataForPublish( {} );

                                                                      }
                                                        }
                                                        // state.sizeDataRecord = action.payload.data;
                                                        // state.totalCountSizeRecord=action.payload.totalCountData;
                                                        state.error =null;
                                                        state.isLoading=false;
                                                        state.isRedirect=false;
                                                        state.isDelete=false;
                                          })
                            .addCase(patternPublishToProductionAsync.rejected, (state, action) => {
                                                        state.status = 'idle';
                                                        state.isLoading=false;
                                                        // state.sizeDataRecord = [];
                                                        // state.totalCountSizeRecord=0;
                                                        state.isDelete=false;
                                                        // state.error = action.payload.message || "Failed to fetch";
                                                        state.isRedirect = action.payload?.isRedirected ? true : false;
                                                        if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                      globalState.removeLocalStorage("user_authenticated");
                                                                      globalState.removeLocalStorage("user");
                                                        }
                                                        
                                          })
                            .addCase(updateSizeDataRecordAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
              
                                          })
                                          .addCase(updateSizeDataRecordAsync.fulfilled, (state, action) => {
                                                        state.status = 'idle';
                                                        let dataMatching=action.payload.data;
                                                        let newData =[...state.sizeDataRecord];
                                                        let findIndex =newData.findIndex(item=> item.ID===dataMatching.ID && item.VariationID === dataMatching.VariationID);
                                                        newData[findIndex]=dataMatching;
                                                        state.sizeDataRecord=newData;
                                                        state.error =null;
                                                        state.isLoading=false;
                                                        state.isRedirect=false;
                                                        state.isDelete = false;
                                                        if ( action.meta.arg?.table )
                                                        {
                                                                      const { table } = action.meta.arg;
                                                                      table.setEditingRow(null);
                                                        }
                                          })
                                          .addCase(updateSizeDataRecordAsync.rejected, (state, action) => {
                                                        state.status = 'idle';
                                                        state.isLoading=false;
                                                        state.error = action.payload.message || "Failed to fetch";
                                                        state.isRedirect = action.payload?.isRedirected ? true : false;
                                                        state.isDelete = false;
                                                        if ( action.meta.arg?.table )
                                                        {
                                                                      const { table } = action.meta.arg;
                                                                      table.setEditingRow(null);
                                                                      
                                                        }
                                                        if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                      globalState.removeLocalStorage("user_authenticated");
                                                                      globalState.removeLocalStorage("user");
                                                        }
                                          })
                                          .addCase(deleteSizeDataRecordAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
              
                                          })
                                          .addCase(deleteSizeDataRecordAsync.fulfilled, (state, action) => {
                                                        state.status = 'idle';
                                                        state.error =null;
                                                        state.isDelete=true;
                                                        state.isLoading=false;
                                                        state.isRedirect=false;
                                                        state.isDelete=true;
                                          })
                                          .addCase(deleteSizeDataRecordAsync.rejected, (state, action) => {
                                                        state.status = 'idle';
                                                        state.isLoading=false;
                                                        state.isDelete=false;
                                                        state.error = action.payload.message || "Failed to fetch";
                                                        state.isRedirect = action.payload?.isRedirected ? true : false;
                                                        if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                      globalState.removeLocalStorage("user_authenticated");
                                                                      globalState.removeLocalStorage("user");
                                                        }
                                          })
                                          .addCase(deleteMultipleRowsRecordAsync.pending, (state) => {
                                            state.status = 'loading';
                                            state.isLoading=true;
                
                                            })
                                            .addCase(deleteMultipleRowsRecordAsync.fulfilled, (state, action) => {
                                                          state.status = 'idle';
                                                          state.error =null;
                                                          state.isDelete=true;
                                                          state.isLoading=false;
                                                          state.isRedirect=false;
                                                          state.isDelete=true;
                                                          if(action.meta?.arg?.setRowSelection){
        
                                                            let {setRowSelection,table}=action.meta?.arg;
                                                            setRowSelection({});
                                                            table.resetRowSelection(true);
                                                            table.resetRowPinning(true)
                                                          }
                                            })
                                            .addCase(deleteMultipleRowsRecordAsync.rejected, (state, action) => {
                                                          state.status = 'idle';
                                                          state.isLoading=false;
                                                          state.isDelete=false;
                                                          state.error = action.payload.message || "Failed to fetch";
                                                          state.isRedirect = action.payload?.isRedirected ? true : false;
                                                          if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                        globalState.removeLocalStorage("user_authenticated");
                                                                        globalState.removeLocalStorage("user");
                                                          }
                                            })
                            .addCase(updateMainDataRecordAsync.pending, (state) => {
                            state.status = 'loading';
                            state.isLoading=true;

                            })
                            .addCase(updateMainDataRecordAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                          let dataMatching=action.payload.data;
                                          let newData =[...state.dataRecord];
                                          let findIndex =newData.findIndex(item=> item.ID===dataMatching.ID );
                                          newData[findIndex]=dataMatching;
                                          state.dataRecord = newData;
                                          // state.totalCountRecord=action.payload.totalCountData;
                                          state.error =null;
                                          state.isLoading=false;
                                          state.isRedirect=false;
                                          state.isDelete = false;
                                          

                            })
                            .addCase(updateMainDataRecordAsync.rejected, (state, action) => {
                                          state.status = 'idle';
                                          state.isLoading=false;
                                          // state.dataRecord = [];
                                          // state.totalCountRecord=0;
                                          state.isDelete=false;
                                          state.error = action.payload.message || "Failed to fetch";
                                          state.isRedirect = action.payload?.isRedirected ? true : false;
                                          if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                        globalState.removeLocalStorage("user_authenticated");
                                                        globalState.removeLocalStorage("user");
                                          }
                                          
                            })
                            .addCase(deleteMainDataRecordAsync.pending, (state) => {
                            state.status = 'loading';
                            state.isLoading=true;

                            })
                            .addCase(deleteMainDataRecordAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                          // let dataMatching=action.payload.data;
                                          // let stateData =[...state.dataRecord];
                                          // let newData =stateData.map(item=>{
                                          //               if(item.ID !==dataMatching.ID){
                                          //                             return item
                                          //               }
                                          // }  );
                                          // // newData[findIndex]=dataMatching;
                                          // state.dataRecord = newData;
                                          // // state.dataRecord = action.payload.data;
                                          // state.totalCountRecord= state.totalCountRecord-1;
                                          state.error =null;
                                          state.isLoading=false;
                                          state.isRedirect=false;
                                          state.isDelete=true;
                            })
                            .addCase(deleteMainDataRecordAsync.rejected, (state, action) => {
                                          state.status = 'idle';
                                          state.isLoading=false;
                                          state.isDelete=false;
                                          state.error = action.payload.message || "Failed to fetch";
                                          state.isRedirect = action.payload?.isRedirected ? true : false;
                                          if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                        globalState.removeLocalStorage("user_authenticated");
                                                        globalState.removeLocalStorage("user");
                                          }
                                          
                            })
                            .addCase(addNewSizeDataRecordAsync.pending, (state) => {
                              state.status = 'loading';
                              state.isLoading=true;
  
                              })
                              .addCase(addNewSizeDataRecordAsync.fulfilled, (state, action) => {
                                            state.status = 'idle';
                                            state.error =null;
                                            state.isLoading=false;
                                            state.isRedirect=false;
                                            state.isDelete=true;
                                            state.sizeDataRecord.push(action.payload.data);
                                            state.totalCountSizeRecord+=1;
                                            if(action.meta.arg?.props){
                                              action.meta.arg?.props?.setOpen(false);
                                              // getDataFunction();
                                  
                                              
                                            }
                              })
                              .addCase(addNewSizeDataRecordAsync.rejected, (state, action) => {
                                            state.status = 'idle';
                                            state.isLoading=false;
                                            state.isDelete=false;
                                            state.error = action.payload.message || "Failed to fetch";
                                            state.isRedirect = action.payload?.isRedirected ? true : false;
                                            if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                          globalState.removeLocalStorage("user_authenticated");
                                                          globalState.removeLocalStorage("user");
                                            }
                                            
                              })
                            
                            .addCase(getSizeDataRecordAsync.pending, (state) => {
                                          state.status = 'loading';
                                          state.isLoading=true;
              
                                          })
                            .addCase(getSizeDataRecordAsync.fulfilled, (state, action) => {
                                                        state.status = 'idle';
                                                        state.sizeDataRecord = action.payload.data;
                                                        state.totalCountSizeRecord=action.payload.totalCountData;
                                                        state.error =null;
                                                        state.isLoading=false;
                                                        state.isRedirect=false;
                                                        state.isDelete=false;
                                          })
                            .addCase(getSizeDataRecordAsync.rejected, (state, action) => {
                                                        state.status = 'idle';
                                                        state.isLoading=false;
                                                        state.sizeDataRecord = [];
                                                        state.totalCountSizeRecord=0;
                                                        state.isDelete=false;
                                                        state.error = action.payload.message || "Failed to fetch";
                                                        state.isRedirect = action.payload?.isRedirected ? true : false;
                                                        if(typeof(action.payload)=== 'object' &&  action.payload?.isRedirected ){
                                                                      globalState.removeLocalStorage("user_authenticated");
                                                                      globalState.removeLocalStorage("user");
                                                        }
                                                        
                                          })
                            .addCase(resetRedirectAsync.fulfilled, (state, action) => {
                                          state.status = 'idle';
                                          state.dataRecord = [];
                                          state.sizeDataRecord = [];
                                          state.totalCountRecord=0;
                                          state.totalCountSizeRecord=0;
                                          state.error =null;
                                          state.isLoading=false;
                                          state.isRedirect=false;
                                          state.isDelete=false;
                            })

              }
})

// Action creators are generated for each case reducer function
export const selectDataRecord = (state) => state.getDataRecord.dataRecord;
export const selectSizeDataRecord = (state) => state.getDataRecord.sizeDataRecord;
export const selectIsFetchedData = (state) => state.getDataRecord.isFetchedData;
export const selectTotalCountData =(state)=>state.getDataRecord.totalCountRecord;
export const selectTotalCountSizeData =(state)=>state.getDataRecord.totalCountSizeRecord;
export const selectErrorFetch =(state)=>state.getDataRecord.error;
export const selectIsLoading =(state)=> state.getDataRecord.isLoading;
export const selectIsRedirect=(state)=>state.getDataRecord.isRedirect;
export const selectIsDelete=(state)=>state.getDataRecord.isDelete;
export default getDataSlice.reducer