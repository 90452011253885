import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import iconLogo from "../../../assets/svgs/ditto-logo.svg";
import { notify } from "../../common/Notify";
import Loadershow from "../../common/Loadershow";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  loginUserAsync,
  selectIsLoading,
  forgotPasswordAsync,
  selectUserInfo,
  selectIsLoginUser,
} from "../authSlice";
import { useForm } from "react-hook-form";
// import DropDownBrand from "./DropDownBrand";
// import { selectBrandOption } from "../../app/Constaint";
const ForgotPassword = () => {
  const [showLoader, setShowLoader] = useState(false);
  // const [selectedBrandOption, setSelectedBrandOption] = useState(selectBrandOption[0]);
  const user = useSelector(selectUserInfo);
  const isLoginUser = useSelector(selectIsLoginUser);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFormSubmit = (data) => {
    // console.log(data);
    // data={...data,brand:selectedBrandOption.value}
    dispatch(forgotPasswordAsync(data));
  };

  const isLoading = useSelector(selectIsLoading);
  // const handleChangeBrandOption =(e)=>{
  //               var filterOption =selectBrandOption.filter((option,index)=>{
  //                             return option.value ===e
  //               });

  //               setSelectedBrandOption({...filterOption[0]});
  // }
  return (
    <>
      {isLoading && <Loadershow show={isLoading} />}
      {Object.keys(user).length &&
        Object.keys(user).length > 0 &&
        isLoginUser && <Navigate to={"/app/dashboard"} replace={true} />}
      <div
        className={`isolate bg-white px-6 py-6 sm:py-6 lg:px-8 ${
          isLoading ? " opacity-25" : " opacity-100"
        }`}>
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mt-7 relative">
          <div className=" bg-white shadow-2xl border border-[#fce300] shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300]  flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8 sm:mx-auto sm:w-full sm:max-w-[45rem]">
            <div className="">
              <img
                className="mx-auto h-10 w-auto"
                src={iconLogo}
                alt="Ditto AirTable"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Forgot password of your Account
              </h2>
            </div>

            <div className="mt-10 ">
              <form className="space-y-6" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      {...register("email", { required: true })}
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors.email && errors.email?.type === "required" && (
                    <p role="alert" className="text-red-600">
                      <span className="text-red-600">- </span> Email is required
                    </p>
                  )}
                </div>
                {/* <div className="py-2 ">
                                                                                                                <div>
                                                                                                                <DropDownBrand  selected={selectedBrandOption} handleChangeBrandOption={handleChangeBrandOption} selectBrandOption ={selectBrandOption}  />
                                                                                                                
                                                                                                                </div>
                                                                                                  </div> */}

                <div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link
                        to="/app/login"
                        className="font-semibold text-[#fce300] hover:text-[#fce300]">
                        Remember password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#fce300] px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:text-white shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                    Forgot Password
                  </button>
                </div>
              </form>

              <p className="mt-10 text-center text-sm text-gray-500">
                Not a member?{" "}
                <Link
                  to="/app/signup"
                  className="font-semibold leading-6 text-[#fce300] hover:text-[#fce300]">
                  Start a 14 day free trial
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
