import {environment} from "../../environment/environment"
export const globalState = {
	setLocalStorage: (key, value) => {
		// console.log(window);
                            localStorage.setItem(key,value);
		sessionStorage.setItem(key, value);
                            return true;
	},
	getLocalStorage: (key) => {
        // console.log(window);
                            localStorage.getItem(key);
		return sessionStorage.getItem(key);
	},
	removeLocalStorage: (key) => {
        // console.log(window);
                            localStorage.removeItem(key);
		return sessionStorage.removeItem(key);
	},
	getBearerToken: () => {
		if (sessionStorage.getItem("user_authenticated") !== "true") {
			return environment.Bearer;
		} else {
			return sessionStorage.getItem("auth_token");
		}
	}

}