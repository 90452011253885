import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import { useForm } from "react-hook-form";
import { selectIsLoginUser, selectUserInfo } from "../../auth/authSlice";
import Termandpolicy from "./Termandpolicy";
import { useDispatch, useSelector } from "react-redux";
import { contactSupportAsync } from "./contactFeature/ContactSlice";
import { notify } from "../../common/Notify";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Contactsupport = () => {
  const [agreed, setAgreed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const isLoginUser = useSelector(selectIsLoginUser);
  useEffect(() => {
    if (!user || !isLoginUser) navigate("/app/login", { replace: true });
  }, [user, isLoginUser,navigate]);
  const onSubmitContact = (data) => {
    if (!agreed) {
      notify.warning("Please accept term & policy");
      return false;
    }
    if (!data) return false;
    dispatch(
      contactSupportAsync({
        data: JSON.stringify(data),
        token: user.token,
        url: "contact/support",
        reset,
        clearErrors,
      })
    );
  };
  const handleToggleSwitch = (e) => {
    if (!agreed) setOpenModal(true);
    else setAgreed(false);
  };
  const handleAcceptTerms = (e, action) => {
    if (action === "reject") {
      setAgreed(false);
    } else {
      setAgreed(true);
    }
    setOpenModal(false);
  };
  return (
    <>
      <div className="isolate  px-6 py-24 sm:py-20 lg:px-8">
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto w-full text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Contact Support
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Let us know how we can help
          </p>
        </div>
        <div className="drop-shadow-2xl rounded-lg bg-white mx-auto shadow-2xl border border-[#fce300] shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300] p-4 max-w-[65rem] mt-8">
          <form
            onSubmit={handleSubmit(onSubmitContact)}
            className="mx-auto my-6 w-10/12 sm:my-10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 ">
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Full name<span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    {...register("fullName", { required: true })}
                    id="fullName"
                    autoComplete="fullName"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.fullName?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Full name is
                    required
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="Brand_Company"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Brand/Company<span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    {...register("Brand_Company", { required: true })}
                    id="Brand_Company"
                    autoComplete="Brand_Company"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.Brand_Company?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Brand/Company is
                    required
                  </p>
                )}
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="subject"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Subject<span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    {...register("subject", { required: true })}
                    id="subject"
                    autoComplete="organization"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.subject?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Subject is required
                  </p>
                )}
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Email<span className="text-red-600">*</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    {...register("email", { required: true })}
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.email?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Email is required
                  </p>
                )}
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Phone number<span className="text-red-600">*</span>
                </label>
                <div className="relative mt-2.5">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <select
                      id="country"
                      {...register("country")}
                      className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-[#fce300] focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm">
                      <option>US</option>
                      <option>CA</option>
                      <option>EU</option>
                    </select>
                    <ChevronDownIcon
                      className="pointer-events-none absolute right-3 top-0 h-full w-5 text-[#fce300]"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="tel"
                    {...register("phoneNumber", { required: true })}
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.phoneNumber?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Phone Number is
                    required
                  </p>
                )}
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    {...register("message")}
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
                {errors.message?.type === "required" && (
                  <p role="alert" className="text-red-600">
                    {" "}
                    <span className="text-red-600">- </span> Message is required
                  </p>
                )}
              </div>
              <div
                onClick={(e) => handleToggleSwitch(e)}
                className="sm:col-span-2 cursor-pointer">
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={agreed}
                      // onChange={(e) => handleToggleSwitch(e)}
                      className={classNames(
                        agreed ? "bg-[#fce300]" : "bg-gray-200",
                        "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]"
                      )}>
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          agreed ? "translate-x-3.5" : "translate-x-0",
                          "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                  <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                    By selecting this, you agree to our{" "}
                    <span className="font-semibold text-[#fce300]">
                      privacy&nbsp;policy
                    </span>
                    .
                  </Switch.Label>
                </Switch.Group>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-[#fce300] px-3.5 py-2.5 text-center text-sm font-semibold text-gray-800 shadow-sm  hover:border-[#fce300] focus-visible:border-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Termandpolicy
        open={openModal}
        setOpen={setOpenModal}
        handleAcceptTerms={handleAcceptTerms}
      />
      <div className=" relative w-full mt-4">
        <Footer />
      </div>
    </>
  );
};

export default Contactsupport;
