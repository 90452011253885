import {config} from "../../../common/Constraint";
const baseUrl=`/api/`;
// const baseUrl=`/api/`;
export const getDataRecord=({url,token})=>{
              return new Promise(async (resolve, reject) => {
                            const bearer = 'Bearer ' + token;
                            try {
                                          const response = await fetch(`${baseUrl}${url}`,
                                          {
                                                        method: 'GET',
                                                        withCredentials: true,
                                                        headers: {
                                                                      'Authorization': bearer, //it can be iPhone or your any other attribute
                                                                      'Content-Type': 'application/json',
                                                                      'Access-Control-Allow-Origin':"*"
                                                        }
                                                        });
                                          if (response.ok) {
                                          const data = await response.json();
                                          resolve({ data });
                                          } else {
                                          const error =typeof(response) === 'object' ? await response.json():  await response.text();
                                          reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}


export const patternPublish=({url,token,data})=>{
              return new Promise(async (resolve, reject) => {
                            const bearer = 'Bearer ' + token;
                            try {
                                          const response = await fetch(`${baseUrl}${url}`,
                                          {
                                                        method: 'POST',
                                                        withCredentials: true,
                                                        headers: {
                                                                      'Access-Control-Allow-Origin':"*",
                                                                      'Content-Type': 'application/json',
                                                                      'Authorization': bearer, //it can be iPhone or your any other attribute
                                                        },
                                                        body:data
                                                        });
                                          if (response.ok) {
                                          const responseData = await response.json();
                                          resolve({ data:responseData });
                                          } else {
                                          const error =typeof(response) === 'object' ? await response.json():  await response.text();
                                          reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}
export const updateDataRecord=({url,token,data})=>{
              return new Promise(async (resolve, reject) => {
                            const bearer = 'Bearer ' + token;
                            try {
                                          const response = await fetch(`${baseUrl}${url}`,
                                          {
                                                        method: 'POST',
                                                        withCredentials: true,
                                                        headers: {
                                                                      'Access-Control-Allow-Origin':"*",
                                                                      'Authorization': bearer, //it can be iPhone or your any other attribute
                                                        },
                                                        body:data
                                                        });
                                          if (response.ok) {
                                          const responseData = await response.json();
                                          resolve({ data:responseData });
                                          } else {
                                          const error =typeof(response) === 'object' ? await response.json():  await response.text();
                                          reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}
export const updateSizeDataRecord=({url,token,data})=>{
              return new Promise(async (resolve, reject) => {
                            const bearer = 'Bearer ' + token;
                            try {
                                          const response = await fetch(`${baseUrl}${url}`,
                                          {
                                                        method: 'POST',
                                                        withCredentials: true,
                                                        headers: {
                                                                      // 'Content-Type': 'application/json',
                                                                      'Access-Control-Allow-Origin':"*",
                                                                      'Authorization': bearer, //it can be iPhone or your any other attribute
                                                        },
                                                        body:data
                                                        });
                                          if (response.ok) {
                                          const responseData = await response.json();
                                          resolve({ data:responseData });
                                          } else {
                                          const error =typeof(response) === 'object' ? await response.json():  await response.text();
                                          reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}
export const deleteDataRecord=({url,token,data})=>{
              return new Promise(async (resolve, reject) => {
                            const bearer = 'Bearer ' + token;
                            try {
                                          const response = await fetch(`${baseUrl}${url}`,
                                          {
                                                        method: 'DELETE',
                                                        withCredentials: true,
                                                        headers: {
                                                                      'Authorization': bearer, //it can be iPhone or your any other attribute
                                                                      'Content-Type': 'application/json',
                                                                      'Access-Control-Allow-Origin':"*"
                                                        },
                                                        body:JSON.stringify(data)
                                                        });
                                          if (response.ok) {
                                          const data = await response.json();
                                          resolve({ data });
                                          } else {
                                          const error =typeof(response) === 'object' ? await response.json():  await response.text();
                                          reject(error);
                                          }
                                          } catch (error) {
                                          reject( error );
                                          }
                            
                            });
}