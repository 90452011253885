import React from 'react'
import Teams from '../Static/Teams';
import AboutConnectingPhase from '../Static/AboutConnectingPhase';
import Footer from '../../common/Footer';
const About = () => {
  return (
    <><div className="isolate  px-6 py-12 sm:py-20 lg:px-8">
      <div
        className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
        aria-hidden="true"
      ><div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />

      </div>
      <div className=''>
        <Teams />
      </div>
      <AboutConnectingPhase />

    </div>
    <Footer />
    </>
  )
}

export default About;