import React from "react";
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
const Footer = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="relative overflow-hidden bg-gray-900 py-12 sm:py-20 lg:py-26">
      <div className="mx-auto max-w-[85rem] px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              The latest Ditto updates, delivered straight to your inbox.
            </p>
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  {...register("email", { required: true })}
                  autoComplete="email"
                  type="email"
                  className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                  placeholder="@ Enter your email"
                />

                <button
                  type="submit"
                  className="flex-none rounded-md bg-[#fce300] px-3.5 py-2.5 text-sm font-semibold text-gray-800 shadow-sm hover:bg-white hover:text-[#fce300] hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-[#fce300]">
                  Subscribe
                </button>
              </div>
              {errors.email && errors.email?.type === "required" && (
                <p role="alert" className="text-red-600">
                  <span className="text-red-600">- </span> Email is required
                </p>
              )}
            </form>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md  p-2 ring-1 ring-white/10 bg-[#fce300]">
                <CalendarDaysIcon
                  className="animate-bounce h-6 w-6 text-gray-800 bg-[#fce300]"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">No Spam</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Access exclusive offers, customer spotlights, new features, and
                more.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md  p-2 ring-1 ring-white/10 bg-[#fce300]">
                <HandRaisedIcon
                  className="animate-bounce h-6 w-6 text-gray-800 bg-[#fce300]"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">Follow us!</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                <div className="flex flex-row justify-center align-middle items-center gap-x-5 mt-10">
                  <a
                    href="https://www.facebook.com/dittopatterns"
                    target="_blank"
                    title="Go To Facebook">
                    <FacebookRoundedIcon
                      className="text-[#fce300]"
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </a>
                  <a href="" target="_blank" title="Go to Instagram">
                    <InstagramIcon
                      className="text-[#fce300]"
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.pinterest.com/dittopatterns/"
                    target="_blank"
                    title="Go to Pinterest">
                    <PinterestIcon
                      className="text-[#fce300] "
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/dittopatterns/"
                    target="_blank"
                    title="Go to LinkedIn">
                    <LinkedInIcon
                      className="text-[#fce300] "
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCA9v7-H5Pp-5OIFDB8_78DQ"
                    target="_blank"
                    title="Go to Youtube">
                    <YouTubeIcon
                      className="text-[#fce300] "
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </a>
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div
        className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
        aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </div>
  );
};

export default Footer;
