import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import iconLogo from "../../../assets/svgs/ditto-logo.svg";
import { notify } from "../../common/Notify";
import Loadershow from "../../common/Loadershow";
import { useDispatch, useSelector } from "react-redux";
import {
  EyeIcon,
  EyeSlashIcon,
  MinusCircleIcon,
  CheckIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import {
  loginUserAsync,
  selectIsLoading,
  selectUserInfo,
  selectIsLoginUser,
  checkAuthUserAsync,
} from "../authSlice";
import { useForm } from "react-hook-form";
// import DropDownBrand from "./DropDownBrand";
import { selectBrandOption, patterns } from "../../app/Constaint";
import { globalState } from "../../app/globalState";
import {
  resetRedirectAsync,
  selectIsRedirect,
} from "../../components/Datatable/getDataRecord/getDataRecordSlice";

const Login = () => {
  const [showLoader, setShowLoader] = useState(false);
  const isLoginUser = useSelector(selectIsLoginUser);
  const user = useSelector(selectUserInfo);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const isRedirect = useSelector(selectIsRedirect);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [selectedBrandOption, setSelectedBrandOption] = useState(selectBrandOption[0]);

  // const handleChangeBrandOption =(e)=>{
  //               var filterOption =selectBrandOption.filter((option,index)=>{
  //                             return option.value ===e
  //               });

  //               setSelectedBrandOption({...filterOption[0]});
  // }
  const onFormSubmit = (data) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // console.log(selectedBrandOption);
    // if(selectedBrandOption.id===0 || selectedBrandOption.value === null){
    //               notify.warning("Please Choose a Brand to Login");
    //               return false;
    // }
    // data.brand =selectedBrandOption.value;
    // if(selectedBrandOption)
    dispatch(loginUserAsync(data));
    reset();
    clearErrors();
  };
  useEffect(() => {
    dispatch(checkAuthUserAsync());
    if (isRedirect) {
      dispatch(resetRedirectAsync());
    }
  }, []);
  const isLoading = useSelector(selectIsLoading);

  return (
    <>
      {isLoading && <Loadershow show={isLoading} />}
      {user && isLoginUser && <Navigate to={`/app/dashboard`} replace={true} />}
      <div
        className={`isolate  px-6 py-6 sm:py-6 lg:px-8 ${
          isLoading ? " opacity-25" : " opacity-100"
        }`}>
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="my-7 relative">
          <div className=" bg-white shadow-2xl border border-[#fce300] shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300]  flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8 sm:mx-auto sm:w-full sm:max-w-[45rem]">
            <div className="">
              <img
                className="mx-auto h-10 w-auto"
                src={iconLogo}
                alt="Ditto AirTable"
              />
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>
            <div className="mt-5 ">
              <form className="space-y-6" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Email address <span className="text-red-700">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      {...register("email", { required: true })}
                      type="email"
                      autoComplete="email"
                      placeholder=" @ Enter the email"
                      className="block w-full pl-7 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors.email && errors.email?.type === "required" && (
                    <p role="alert" className="text-red-600">
                      <span className="text-red-600">- </span> Email is required
                    </p>
                  )}
                </div>
                {/* <div className="py-2 ">
                                                                                                                <div>
                                                                                                                <DropDownBrand  selected={selectedBrandOption} handleChangeBrandOption={handleChangeBrandOption} selectBrandOption ={selectBrandOption} title={"Brand"} />
                                                                                                                
                                                                                                                </div>
                                                                                                  </div> */}
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Password <span className="text-red-700">*</span>
                    </label>
                  </div>
                  <div className="mt-2 relative">
                    <input
                      id="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password should be atleast 8 Characters",
                        },
                        maxLength: {
                          value: 30,
                          message: "Password should be at most 30 Characters",
                        },
                        pattern: {
                          value: patterns,
                          message:
                            "Password should be includes special,Lower & Upper character",
                        },
                      })}
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      placeholder=" @ Enter the password"
                      className=" block w-full pl-7 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                    <span
                      className=" absolute flex flex-row justify-center items-center top-2 right-5 "
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <EyeSlashIcon className="w-4 h-4" />
                      ) : (
                        <EyeIcon className="w-4 h-4" />
                      )}
                    </span>
                  </div>
                  {errors.password && errors?.password?.message && (
                    <p role="alert" className="text-red-600">
                      <span className="text-red-600">- </span>{" "}
                      {errors.password.message}
                    </p>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#fce300] px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:text-white shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                    {isLoading ? (
                      <>
                        <ArrowPathIcon className="w-5 h-5 animate-spin " />
                      </>
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </div>
              </form>
              <div className="mt-10 flex flex-row justify-between items-center">
                <div className="text-sm">
                  <Link
                    to="/app/forgot-password"
                    className="font-semibold text-[#fce300] hover:text-gray-700">
                    Forgot password?
                  </Link>
                </div>
                {/* <p className="text-center text-sm text-gray-500">
                                                                                                  Not a member?{" "}
                                                                                                  <Link
                                                                                                                to="/app/signup"
                                                                                                                className="font-semibold leading-6 text-[#fce300] hover:text-gray-700">
                                                                                                                Start a Create your Account
                                                                                                  </Link>
                                                                                    </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
