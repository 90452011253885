// contactSupport
import { config } from "../../../common/Constraint";
const baseUrl = `/api/`;
export const contactSupport = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}contact/support`, {
        method: "POST",
        body: data.data,
        headers: {
          Authorization: bearer,
        },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};
