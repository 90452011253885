import React, { useEffect } from 'react'
import { Outlet,useNavigate,useParams,useLocation } from 'react-router-dom';
import { checkAuthUserAsync } from '../../auth/authSlice';
import { useDispatch } from 'react-redux';
const Root = ({children}) => {
              const location =useLocation();
              const navigate =useNavigate();
              const dispatch =useDispatch();
              
              useEffect(()=>{
                            if(location.pathname ==='/app/' || location.pathname ==='/app' || location.pathname ==='/'){
                                          navigate("/app/dashboard",{replace:true})
                            }
              },[]);
              useEffect(()=>{
                dispatch(checkAuthUserAsync())
              },[dispatch])
  return (
    <>
    {children}
              <Outlet />
    </>
  )
}

export default Root;