import React from 'react'
import ResetPassword from '../../auth/components/ResetPassword';
const Resetpassword = () => {
              return (
                            <>
                                          <ResetPassword />
                            </>
              )
}

export default Resetpassword;