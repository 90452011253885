import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './features/app/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
const contextClass = {
  success: "bg-[#fce300] text-black",
  error: "bg-[#a60818] text-white",
  info: "bg-[#3076d1]",
  warning: "bg-indigo-600",
  default: "bg-indigo-600",
  dark: "bg-white-600 text-white",
};
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <App />
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        toastClassName={({ type }) => contextClass[type || "default"] +
        " relative w-full flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer items-center"
        }
        bodyClassName={() => "w-full justify-start flex items-center text-sm p-3"}
        />
        </Provider>
  </React.StrictMode>
);
