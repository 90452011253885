import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import iconLogo from "../../../assets/svgs/ditto-logo.svg";
import { notify } from "../../common/Notify";
import Loadershow from "../../common/Loadershow";
import { useDispatch, useSelector } from "react-redux";
import {
  EyeIcon,
  EyeSlashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import {
  createUserAsync,
  selectIsLoading,
  selectIsLoginUser,
  selectUserInfo,
  selectNewCreateUser,
  selectIsNewUserCreate,
  resetNewUserAsync,
} from "../authSlice";
import { useForm } from "react-hook-form";
import DropDownBrand from "./DropDownBrand";
import {
  selectBrandOption,
  patterns,
  selectRoleOption,
} from "../../app/Constaint";
import isEmail from "validator/lib/isEmail";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { DocumentPlusIcon, DocumentCheckIcon } from "@heroicons/react/24/solid";
import { renameFile } from "../../common/Processhelper";
const Signup = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isLoginUser = useSelector(selectIsLoginUser);
  const isNewUser = useSelector(selectIsNewUserCreate);
  const newCreateUser = useSelector(selectNewCreateUser);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const isUserFetch = useSelector(selectIsLoginUser);
  const user = useSelector(selectUserInfo);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm();
  
  const [selectedBrandOption, setSelectedBrandOption] = useState(
    selectBrandOption[0]
  );
  const [selectedRoleOption, setSelectedRoleOption] = useState(
    selectRoleOption[0]
  );
  const handleChangeBrandOption = (e) => {
    var filterOption = selectBrandOption.filter((option, index) => {
      return option.value === e;
    });
    setSelectedBrandOption({ ...filterOption[0] });
  };
  const handleChangeRoleOption = (e) => {
    var filterOption = selectRoleOption.filter((option, index) => {
      return option.value === e;
    });
    setSelectedRoleOption({ ...filterOption[0] });
  };
  useEffect(() => {
    // if(isLoginUser && user){
    //         navigate("/app/dashboard",{replace:true});
    // }
    if (!user && !isLoginUser) {
      navigate("/app/login", { replace: true });
    }
    if (isLoginUser && user && user.role !== "admin") {
      navigate("/app/dashboard", { replace: true });
    }
  }, [navigate,user,isLoginUser]);
  useEffect(() => {
    if (isNewUser && Object.keys(newCreateUser).length > 0) {
      reset();
      clearErrors();
      setShowPassword(false);
      setSelectedBrandOption({ ...selectBrandOption[0] });
      setSelectedRoleOption({ ...selectRoleOption[0] });
    }
  }, [isNewUser,clearErrors,newCreateUser,reset]);
  const getResetNewUser = useCallback(() => {
    dispatch(resetNewUserAsync());
  },[dispatch])
  useEffect(() => {
    getResetNewUser();
  }, [getResetNewUser]);
 
  

  const onFormSubmit = (data) => {
    if (isEmail(data.email)) {
      let formData = new FormData();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      // notify.success("Valid Email");
      if (selectedBrandOption.value === null) {
        notify.error("Please Choose Brand");
        return false;
      }
      if (selectedRoleOption.value === null) {
        notify.error("Please Choose Role");
        return false;
      }
      if (
        data &&
        "profile_pic" in data &&
        data.profile_pic !== null &&
        data.profile_pic !== undefined &&
        data.profile_pic.length !== 0
      ) {
        if (Math.round(data.profile_pic[0].size / 1024) > 10240) {
          notify.error("Please Choose Brand");
          return false;
        }
        let newProfileFile = renameFile(
          data.profile_pic[0],
          "Profile_Pic_Name"
        );
        formData.append("profile_pic", newProfileFile);
      }
      delete data?.profile_pic;
      data.brand = selectedBrandOption.value;
      data.role = selectedRoleOption.value;
      // console.log(data);
      formData.append("data", JSON.stringify(data));

      dispatch(createUserAsync({ data: formData, token: user.token }));
      // if(user && isUserFetch){
      //   navigate("/app/dashboard",{replace:true});
      // }
    } else {
      notify.warning("Not Valid Email");
    }
    //
  };
  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
    notify.success("Copied to Clipboard");
  };
  const isLoading = useSelector(selectIsLoading);

  return (
    <>
      {isLoading && <Loadershow show={isLoading} />}
      {user?.role !== "admin" && (
        <Navigate
          to={`${
            location.pathname !== "/app/signup"
              ? location.pathname
              : "/app/dashboard"
          }`}
          replace={true}
        />
      )}
      {/* {(user && isUserFetch) && <Navigate to={`${location.pathname !== "/app/signup"? location.pathname :"/app/dashboard"}`} replace={true} />} */}
      <div
        className={`mb-10 isolate px-6 py-6 sm:py-6 lg:px-8 ${
          isLoading ? " opacity-25" : " opacity-100"
        }`}>
        <div
          className="absolute inset-x-0 top-[4rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[4rem]"
          aria-hidden="true">
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#32323B] to-[#fce300] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="my-7 relative">
          <div className=" bg-white shadow-2xl border border-[#fce300] rounded-lg shadow-[#fce300] md:shadow-2xl md:shadow-[#fce300]  flex min-h-full flex-1 flex-col justify-center px-6 py-20 lg:px-8 sm:mx-auto sm:w-full sm:max-w-[45rem]">
            <div className="">
              <img
                className="mx-auto h-10 w-auto"
                src={iconLogo}
                alt="Ditto AirTable"
              />
            </div>
            {isNewUser && Object.keys(newCreateUser).length > 0 ? (
              <>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Detail of Newly Create Account
                </h2>
                <div className="mt-5 ">
                  <div>
                    <div className="px-4 sm:px-0">
                      <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Applicant Information
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        Personal details and application.
                      </p>
                    </div>
                    <div className="mt-6 border-t border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Full name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                            {newCreateUser.fullName}
                            <dd className="ml-[2rem] text-sm leading-6 text-gray-700 sm:mt-0 ">
                              <span
                                onClick={() => {
                                  handleCopyText(newCreateUser.fullName);
                                }}
                                title="Copy to Full Name">
                                <DocumentDuplicateIcon className="w-6 h-6 text-[#fce300]" />
                              </span>
                            </dd>
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Role for
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                            {newCreateUser.role}
                            <dd className="ml-[2rem] text-sm leading-6 text-gray-700  sm:mt-0">
                              <span
                                onClick={() => {
                                  handleCopyText(newCreateUser.role);
                                }}
                                title="Copy to Role">
                                <DocumentDuplicateIcon className="w-6 h-6 text-[#fce300]" />
                              </span>
                            </dd>
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Email address
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                            {newCreateUser.email}
                            <dd className="ml-[2rem] text-sm leading-6 text-gray-700  sm:mt-0">
                              <span
                                onClick={() => {
                                  handleCopyText(newCreateUser.email);
                                }}
                                title="Copy to Email">
                                <DocumentDuplicateIcon className="w-6 h-6 text-[#fce300]" />
                              </span>
                            </dd>
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Brand
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                            {newCreateUser.brand}
                            <dd className="ml-[2rem] text-sm leading-6 text-gray-700 sm:mt-0">
                              <span
                                onClick={() => {
                                  handleCopyText(newCreateUser.brand);
                                }}
                                title="Copy to Brand">
                                <DocumentDuplicateIcon className="w-6 h-6 text-[#fce300]" />
                              </span>
                            </dd>
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Password
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-between">
                            {newCreateUser.password}
                            <dd className="ml-[2rem] text-sm leading-6 text-gray-700 sm:mt-0">
                              <span
                                onClick={() => {
                                  handleCopyText(newCreateUser.password);
                                }}
                                title="Copy to Password">
                                <DocumentDuplicateIcon className="w-6 h-6 text-[#fce300]" />
                              </span>
                            </dd>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => getResetNewUser()}
                      className="flex w-full justify-center rounded-md bg-[#fce300] px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:text-white shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                      Create New Account
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Create an account
                </h2>
                <div className="mt-5 ">
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                      <label
                        htmlFor="fullName"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Full Name{" "}
                        <span className="text-red-700">
                          <>*</>
                        </span>
                      </label>
                      <div className="mt-2 flex flex-row justify-center items-center">
                        <input
                          id="fullName"
                          {...register("fullName", { required: true })}
                          type="text"
                          autoComplete="fullName"
                          placeholder=" @ Enter the full name"
                          className="block w-full pl-7  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                        />
                      </div>
                      {errors.fullName &&
                        errors.fullName?.type === "required" && (
                          <p role="alert" className="text-red-600">
                            <span className="text-red-600">- </span> Full Name
                            is required
                          </p>
                        )}
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Email address{" "}
                        <span className="text-red-700">
                          <>*</>
                        </span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          {...register("email", { required: true })}
                          type="email"
                          autoComplete="email"
                          placeholder=" @ Enter the email"
                          className="block w-full pl-7 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                        />
                      </div>
                      {errors.email && errors.email?.type === "required" && (
                        <p role="alert" className="text-red-600">
                          <span className="text-red-600">- </span> Email is
                          required
                        </p>
                      )}
                    </div>
                    <div className="py-2 ">
                      <div>
                        <DropDownBrand
                          selected={selectedBrandOption}
                          handleChangeBrandOption={handleChangeBrandOption}
                          selectBrandOption={selectBrandOption}
                          title={"Brand"}
                        />
                      </div>
                    </div>
                    <div className="py-2 ">
                      <div>
                        <DropDownBrand
                          selected={selectedRoleOption}
                          handleChangeBrandOption={handleChangeRoleOption}
                          selectBrandOption={selectRoleOption}
                          title={"Role"}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900">
                          Create Password{" "}
                          <span className="text-red-700">
                            <>*</>
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <input
                          id="password"
                          {...register("password", {
                            required: "Repeat Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password should be minimum 8 Characters",
                            },
                            pattern: {
                              value: patterns,
                              message:
                                "Password should be includes special,Lower & Upper character",
                            },
                          })}
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          placeholder=" @ Enter the password"
                          className=" block w-full pl-7  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                        />
                        <span
                          className=" absolute flex flex-row justify-center items-center top-2 right-5 "
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <EyeSlashIcon className="w-4 h-4" />
                          ) : (
                            <EyeIcon className="w-4 h-4" />
                          )}
                        </span>
                      </div>
                      {errors.password && errors?.password?.message && (
                        <p role="alert" className="text-red-600">
                          <span className="text-red-600">- </span>{" "}
                          {errors.password.message}
                        </p>
                      )}
                      {errors.password &&
                        errors.password?.type === "validate" && (
                          <p role="alert" className="text-red-600">
                            <span className="text-red-600">- </span> Password
                            didn't matched
                          </p>
                        )}
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor="repeatPassword"
                          className="block text-sm font-medium leading-6 text-gray-900">
                          Repeat Password{" "}
                          <span className="text-red-700">
                            <>*</>
                          </span>
                        </label>
                      </div>
                      <div className="mt-2 relative">
                        <input
                          id="repeat-password"
                          {...register("repeatPassword", {
                            required: "Repeat Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password should be minimum 8 Characters",
                            },
                            pattern: {
                              value: patterns,
                              message:
                                "Password should be includes special,Lower & Upper character",
                            },
                            validate: (value, formValues) =>
                              value === formValues.password,
                          })}
                          type={showPassword ? "text" : "password"}
                          autoComplete="repeatPassword"
                          placeholder=" @ Enter the repeat password"
                          className=" block w-full pl-7  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                        />
                        <span
                          className=" absolute flex flex-row justify-center items-center top-2 right-5 "
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <EyeSlashIcon className="w-4 h-4" />
                          ) : (
                            <EyeIcon className="w-4 h-4" />
                          )}
                        </span>
                      </div>

                      {errors.repeatPassword &&
                        errors?.repeatPassword?.message && (
                          <p role="alert" className="text-red-600">
                            <span className="text-red-600">- </span>{" "}
                            {errors.repeatPassword.message}
                          </p>
                        )}
                      {errors.repeatPassword &&
                        errors.repeatPassword?.type === "validate" && (
                          <p role="alert" className="text-red-600">
                            <span className="text-red-600">- </span> Password
                            didn't matched
                          </p>
                        )}
                    </div>
                    <div className="col-span-full">
                      <label
                        htmlFor="profile_pic"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Profile Pic
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-[#fce300] px-6 py-10">
                        <div className="text-center flex flex-col justify-center items-center">
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                              htmlFor="profile_pic"
                              className="relative cursor-pointer rounded-md bg-white font-semibold text-[#fce300] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#fce300] focus-within:ring-offset-2 hover:text-[#fce300]">
                              {!watch(`profile_pic`) ||
                              watch(`profile_pic`).length === 0 ? (
                                <>
                                  <PhotoIcon
                                    className="mx-auto h-12 w-12 text-[#fce300]"
                                    aria-hidden="true"
                                  />
                                  <p className="text-xs leading-5 text-gray-600">
                                    PNG, JPG, JPEG up to 3MB
                                  </p>
                                </>
                              ) : (
                                <>
                                  <strong className="text-gray-900">
                                    {!watch(`profile_pic`)?.length
                                      ? null
                                      : watch(`profile_pic`)[0]?.name}{" "}
                                  </strong>{" "}
                                  <DocumentCheckIcon
                                    className="mx-auto h-12 w-12 text-[#fce300]"
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                              <span>Upload a Image</span>
                              <input
                                id="profile_pic"
                                {...register(`profile_pic`)}
                                type="file"
                                className="sr-only"
                                accept=".jpg, .jpeg, .png"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-2">
                    <input
                      type="text"
                      {...register("Yardage_Image", { required: true })}
                      id="Yardage_Image"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#fce300] sm:text-sm sm:leading-6"
                    />
                  </div> */}
                      <p className="mt-3 text-sm leading-6 text-gray-600">
                        you can add only one image
                      </p>
                      {/* {errors.profile_pic?.type === "required" && (
                        <p role="alert" className="text-red-600">
                          <span className="text-red-600">- </span> Profile Pic
                          is required
                        </p>
                      )} */}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-[#fce300] px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:text-white shadow-sm hover:bg-[#fce300] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#fce300]">
                        Sign UP
                      </button>
                    </div>
                  </form>

                  {/* <p className="mt-10 text-center text-sm text-gray-500">
                                                                                                  Already member?{" "}
                                                                                                  <Link
                                                                                                                to="/app/login"
                                                                                                                className="font-semibold leading-6 text-[#fce300] hover:text-[#fce300]">
                                                                                                                Login your account
                                                                                                  </Link>
                                                                                    </p> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
