import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom';
import { selectIsLoginUser, selectUserInfo, signOutUserAsync } from '../authSlice';

const Signout = () => {
              const dispatch=useDispatch();
              const navigate =useNavigate();
              const isLoginUser= useSelector(selectIsLoginUser);
              const user=useSelector(selectUserInfo);
              useEffect(()=>{
                            dispatch(signOutUserAsync())
              },[dispatch]);
              useEffect(()=>{
                            if(!user || !isLoginUser){
                                          navigate("/app/login",{replace:true})
                            }
              },[user,isLoginUser])
              return (
              <>
              {!user && !isLoginUser && <Navigate to={"/app/login"} replace={true} />}
              </>
              )
}

export default Signout;