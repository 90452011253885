import { contactSupport } from "./ContactAPI";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notify } from "../../../common/Notify";
export const contactSupportAsync = createAsyncThunk(
  "auth/contactSupport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await contactSupport(data);
      data.reset();
      data.clearErrors();
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contactDetails: {},
    isFetchedData: false,
    isLoading: false,
    error: null,
    getAllContactList: [],
    isRedirected: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactSupportAsync.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(contactSupportAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.isLoading = false;
        state.contactDetails = action.payload.contactDetails;
        state.getAllContactList.push(action.payload.contactDetails);
        state.isRedirected = false;
      })
      .addCase(contactSupportAsync.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload.message;
        notify.error(action.payload.message);
        state.isLoading = false;
        if (action.payload?.isRedirected || action.error?.isRedirected) {
          state.isRedirected = true;
        }
      });
  },
});
export const selectContactDetails = (state) => state.contact.contactDetails;
export const selectIsLoadingContact = (state) => state.contact.isLoading;
export const selectGetAllContactList = (state) =>
  state.contact.getAllContactList;
export const selectIsRedirectedContact = (state) => state.contact.isRedirected;
export const selectErrorInfo = (state) => state.contact.error;
export default contactSlice.reducer;
