import { config } from "../common/Constraint";
const baseUrl = `/api/`;
export const createUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}auth/signup`, {
        method: "POST",
        body: data.data,
        headers: {
          Authorization: bearer,
        },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}${data.url}`, {
        method: "POST",
        body: data.data,
        headers: {
          Authorization: bearer,
        },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const forgotPassword = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseUrl}auth/forgot-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const resetpassword = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseUrl}auth/reset-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const loginUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseUrl}auth/login`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json" },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

// getAllUserList
export const getAllUserList = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}auth/${data.url}`, {
        method: "POST",
        body: JSON.stringify(data.data),
        headers: { "Content-type": "application/json", Authorization: bearer },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const checkUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(data);
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}${data.url}`, {
        method: "POST",
        body: JSON.stringify(data.data),
        headers: { "Content-type": "application/json", Authorization: bearer },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const sendOtp = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}auth/${data.url}`, {
        method: "GET",
        headers: { "Content-type": "application/json", Authorization: bearer },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyOtp = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const bearer = "Bearer " + data.token;
      const response = await fetch(`${baseUrl}auth/${data.url}`, {
        method: "POST",
        body: JSON.stringify(data.data),
        headers: { "Content-type": "application/json", Authorization: bearer },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error =
          typeof response === "object"
            ? await response.json()
            : await response.text();
        reject(error);
      }
    } catch (error) {
      reject(error);
    }
  });
};
